import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"

import { QuestionCircleOutlined } from "@ant-design/icons"
import { Modal, Form, Input, Tooltip, Button } from "antd"

import { setCookie } from "../../../../../helpers/utils/cookies"
import { MetricsPaths } from "../../../../../helpers/utils/enums"
import {
  generateUniqueID,
  getDefaultMetricsGroupings,
  normalizeCreatedMetrics,
} from "../../../../../helpers/utils/functions"
import {
  generateListKeys,
  prepareData,
  prepareGroupsData,
} from "../../../../../helpers/utils/reports"
import {
  calculateWidth,
  convertToGridUnits,
  measureTextDimensions,
  showErrorMessage,
} from "../../../../../helpers/utils/ui"
import { unApi } from "../../../../../shared/common/api/endpoints/reports/unApi"
import VisitsChart from "../../../../../shared/common/components/reports/ad-chart/Chart"
import AdChart from "../../../../../shared/common/components/reports/ad-chart/Chart"
import {
  StaticGroupings,
  staticMetrics,
} from "../../../../../shared/common/components/reports/CreateMetricsModal"
import Table from "../../../../../shared/common/components/reports/dashboards/Table"
import OrderChart from "../../../../../shared/common/components/reports/order-chart/Chart"
import FirstRegIcon from "../../../../../shared/common/components/reports/params/attribution/icons/FirstRegIcon"
import LastRegIcon from "../../../../../shared/common/components/reports/params/attribution/icons/LastRegIcon"
import TypeIcon from "../../../../../shared/common/components/reports/params/attribution/icons/TypeIcon"
import PaymentChart from "../../../../../shared/common/components/reports/payment-chart/Chart"
import UniversalChart from "../../../../../shared/common/components/reports/un-chart/Chart"
import WebinarChart from "../../../../../shared/common/components/reports/web-chart/Chart"
import { chartDetails } from "../../../../../shared/common/constants/details"
import { listKeysJsonUniversal } from "../../../../../shared/common/constants/metrics"
import { useActions } from "../../../../../shared/common/hooks/useActions"
import { useTypedSelector } from "../../../../../shared/common/hooks/useTypedSelector"
import { DashboardComponent } from "../../../Dashboards"
import { dateObjects, displayObjects } from "../../../helpers/data"
import ButtonsType from "../../ButtonsType"
import CartNumber from "../../Card"
import Params from "../../Params/Params"
import FormManualSelect from "../components/FormManualSelect"
import ModalSettings from "../ModalSettings"

interface Props {
  blockType: string;
  visible: boolean;
  setVisible: React.Dispatch<{ visible: boolean; blockType: string }>;
  onAddBlock: (component: DashboardComponent) => void;
}

const chartsComponents: { [key: string]: ReactElement } = {
  reg: <UniversalChart />,
  ad: <AdChart />,
  web: <WebinarChart />,
  payment: <PaymentChart />,
  visit: <VisitsChart />,
  order: <OrderChart />,
}
const MemoizedTable = React.memo(({ display }: { display: string }) => {
  return <Table display={display} />
})
const attrItems = [
  {
    id: "type_applications",
    name: "Тип регистрации:",
    selectValues: [
      { name: "Нулевые заказы GC", id: "2", icon: null, default: true },
      { name: "Прямая продажа", id: "4", icon: null },
      { name: "Регистрации GC", id: "0", icon: null },
      { name: "SCAN", id: "1", icon: null },
      //{ name: "Ручное добавление", id: "3", icon: null },
    ],
    description: `Выбор типа регистрации по которым нужно создать отчёт:
                    <br><br>
                    Типы регистраци:
                    <br><br>
                    - SCAN - Это данные собираемые скриптом SCAN с форм;<br>
                    - Прямая продажа - эта регистрация создаётся автоматически в SCANе при создании платного заказа. Название регистрации будет являться название платного предложения;<br>
                    - Нулевые заказы GC - Заказы в GetCourse со стоимостью 0 руб.;<br>
                    - Регистрации GC -  Первая регистрация (попадания в базу) в GetCourse;<br>
                    <br><br>
                    <a href="https://help.scananalytics.ru/?p=504" rel="noopener noreferrer" target="_blank">Подробнее</a>.
                    `,
    icon: <TypeIcon />,
  },
  {
    id: "attribution",
    name: "Атрибуция:",
    selectValues: [
      { name: "Первая регистрация", id: "1", icon: <FirstRegIcon /> },
      { name: "Последняя регистрация", id: "2", icon: <LastRegIcon /> },
    ],
    description: `Сервис SCAN позволяет определить к какой регистрации пользователя привязывать заказ. В сервисе 2 типа атрибуции:
                    <br><br>
                    - Первая регистрация <br>
                    - Последняя регистрация <br>
                    <br><br>
                    <a href="https://help.scananalytics.ru/?p=508" rel="noopener noreferrer" target="_blank">Подробнее</a>.
                    `,
    icon: null,
  },
]
const displayItems = [
  {
    id: "date",
    name: "Период:",
    selectValues: dateObjects,
    description: null,
    icon: null,
  },
  {
    id: "display",
    name: "Отображаем:",
    selectValues: displayObjects,
    description: null,
    icon: null,
  },
]
const AddBlockModal: React.FC<Props> = ({
                                          visible,
                                          blockType,
                                          setVisible,
                                          onAddBlock,
                                        }) => {
  const { details } = useTypedSelector((state) => state.details)
  const { filters } = useTypedSelector((state) => state.filter)
  const { attributesDashboard } = useTypedSelector((state) => state.attributes)
  const { attributes } = useTypedSelector((state) => state.attributes)
  const {
    groupings,
    metrics,
    metricsChecked,
    groupsChecked,
    totalChecked,
    percentages,
    filtersTable,
  } = useTypedSelector((state) => state.table)
  const { chartType } = useTypedSelector((state) => state.chart)
  const {
    setGroupings,
    setAttributesDashboardBlocks,
    setDashboardBlockDetails,
    setMetrics,
    setMetricsChecked,
    setAllDataFetching,
    setDashboardsBlocksInitialState,
    setDashboardsBlocksTableInitialState,
    setDashboardsBlocksChartState,
    setGroupingsChecked,
    setPercentages,
    setFilters,
    setTableData,
    setMetaTableFetching,
    setIsNotParamsFilterVisible,
  } = useActions()
  const [ form ] = Form.useForm()
  const [ currentSelect, setCurrentSelect ] = useState(blockType)
  const [ isBasicMetricsVisible, setIsBasicMetricsVisible ] = useState(false)
  const [ isBasicGroupingsVisible, setIsBasicGroupingsVisible ] = useState(false)
  const [ selectedMetrics, setSelectedMetrics ] = useState<any[]>([])
  const [ selectedGroupings, setSelectedGroupings ] = useState<any[]>([])
  const { selectedSchool } = useTypedSelector((state) => state.currentSchool)
  const [ isBlockVisible, setIsBlockVisible ] = useState(false)
  const getStaticMetricDataItems = (pathname: string, additionalMetrics = []) =>
      prepareData(
          [
            ...staticMetrics[pathname as keyof typeof staticMetrics],
            ...[
              {
                name: "Созданные метрики",
                children: additionalMetrics,
              },
            ],
          ],
          "",
          [],
          false
      )

  const getStaticMetricDataListKeys = (pathname: string) =>
      JSON.parse(
          listKeysJsonUniversal[pathname as keyof typeof listKeysJsonUniversal]
      )
  const [ dataItemsMetrics, setDataItemsMetrics ] = useState([])
  const [ listKeysMetrics, setListKeysMetrics ] = useState([])
  const [ dataItemsGroupings, setDataItemsGroupings ] = useState([])
  const [ listKeysGroupings, setListKeysGroupings ] = useState([])
  const [ heading, setHeading ] = useState("")
  const renderView = useMemo(() => {
    const cartProps = {
      selectedMetrics,
      selectedGroupings,
      metricsChecked,
      attribution: attributes,
      date: form.getFieldValue("date"),
      filters,
    }
    let previewContent
    switch (currentSelect) {
      case "number":
        previewContent = <CartNumber {...cartProps} />
        break
      case "chart":
        const dateValue = form.getFieldValue("date")
        previewContent = (
            <div
                className={"table-modal"}
                style={{ background: "white", padding: "10px" }}
            >
              {dateValue ? chartsComponents[dateValue] : <UniversalChart />}
            </div>
        )
        break
      case "table":
        previewContent = (
            <div
                className={"table-modal"}
                style={{ overflowX: "auto", paddingRight: "20px" }}
            >
              <Table display={currentSelect} />
            </div>
        )
        break
      case "heading":
        previewContent = (
            <div
                className={"table-modal"}
                style={{ overflowX: "auto", paddingRight: "20px" }}
            >
              <p style={{ fontWeight: 800 }}>{heading}</p>
            </div>
        )
        break
      default:
        previewContent = <div>Выберите тип</div>
    }

    return previewContent
  }, [
    currentSelect,
    selectedMetrics,
    selectedGroupings,
    metricsChecked,
    attributes,
    filters,
    heading,
  ])
  const handleCloseModal = () => {
    setVisible({ visible: false, blockType: "" })
  }
  const handleListChange = useCallback(
      (
          newList: any[],
          fn1: Dispatch<SetStateAction<any[]>>,
          fn2: (newList: any) => {}
      ) => {
        // fn1(newList)
        fn2({ data: newList })
      },
      []
  )

  /*
    Metrics
     */
  useEffect(() => {
    setFilters([])
    return () => {
      setFilters([])
      setPercentages([])
      setGroupings({ data: [] })
      setMetrics({ data: [] })
      setGroupingsChecked([])
      setMetricsChecked({ data: [] })
      setTableData({ rows: [], total: 0, groupings })
      setIsNotParamsFilterVisible(false)
    }
  }, [])

  useEffect(() => {
    if (currentSelect === "chart" || currentSelect === "table") {
      if (currentSelect === "chart") {
        // setMetricsChecked({data:selectedMetrics})
      }
      setMetrics({ data: selectedMetrics })
      setGroupings({ data: selectedGroupings })
      setMetaTableFetching({ value: true })
    }
  }, [ selectedMetrics, selectedGroupings ])

  const handleSelectChange = (currentSelect: string) => {
    setCookie("chosenDisplay", currentSelect, { expires: 300 })
    setCurrentSelect(currentSelect)
    const periodDateValue = form.getFieldValue("date")
    handlePeriodDateChange(periodDateValue)
  }

  const handlePeriodDateChange = async (value: string) => {
    const getCreatedMetrics = async (pathname: string) => {
      if (selectedSchool?.id) {
        return await unApi
            .getCustomMetrics(selectedSchool?.id, pathname)
            .then((res) => {
              return normalizeCreatedMetrics(res.data)
            })
            .catch((err) => {
              console.log(err)
            })
      }
    }
    type DateKeys = "reg" | "ad" | "order" | "payment" | "web" | "visit";

    const dateToPathname: Record<DateKeys, MetricsPaths> = {
      reg: MetricsPaths.Universal,
      ad: MetricsPaths.AdReports,
      order: MetricsPaths.OrderReports,
      payment: MetricsPaths.PaymentReports,
      web: MetricsPaths.WebinarReports,
      visit: MetricsPaths.Universal,
    }
    const { metrics, groupings } = getDefaultMetricsGroupings(value, form)
    setCookie("dashboardPathname", dateToPathname[value as DateKeys], {
      expires: 3000,
    })
    switch (value) {
      case "reg": {
        setGroupings({ data: groupings })
        setMetrics({ data: metrics })
        setSelectedMetrics(metrics)
        setSelectedGroupings(groupings)
        setDataItemsMetrics(
            getStaticMetricDataItems(
                MetricsPaths.Universal,
                (await getCreatedMetrics(MetricsPaths.Universal)) as []
            )
        )
        setListKeysMetrics(getStaticMetricDataListKeys(MetricsPaths.Universal))
        setDataItemsGroupings(
            prepareGroupsData(StaticGroupings[MetricsPaths.Universal])
        )
        setListKeysGroupings(
            generateListKeys(
                prepareGroupsData(StaticGroupings[MetricsPaths.Universal])
            )
        )
        break
      }
      case "ad": {
        setSelectedMetrics(metrics)
        setSelectedGroupings(groupings)
        setGroupings({ data: groupings })
        setMetrics({ data: metrics })
        setDataItemsMetrics(
            getStaticMetricDataItems(
                MetricsPaths.AdReports,
                (await getCreatedMetrics(MetricsPaths.AdReports)) as []
            )
        )
        setListKeysMetrics(getStaticMetricDataListKeys(MetricsPaths.AdReports))
        setDataItemsGroupings(
            prepareGroupsData(StaticGroupings[MetricsPaths.AdReports])
        )
        setListKeysGroupings(
            generateListKeys(
                prepareGroupsData(StaticGroupings[MetricsPaths.AdReports])
            )
        )
        break
      }
      case "order": {
        setSelectedMetrics(metrics)
        setSelectedGroupings(groupings)
        setGroupings({ data: groupings })
        setMetrics({ data: metrics })
        setDataItemsMetrics(
            getStaticMetricDataItems(
                MetricsPaths.OrderReports,
                (await getCreatedMetrics(MetricsPaths.OrderReports)) as []
            )
        )
        setListKeysMetrics(
            getStaticMetricDataListKeys(MetricsPaths.OrderReports)
        )
        setDataItemsGroupings(
            prepareGroupsData(StaticGroupings[MetricsPaths.OrderReports])
        )
        setListKeysGroupings(
            generateListKeys(
                prepareGroupsData(StaticGroupings[MetricsPaths.OrderReports])
            )
        )
        break
      }
      case "payment": {
        setSelectedMetrics(metrics)
        setSelectedGroupings(groupings)
        setGroupings({ data: groupings })
        setMetrics({ data: metrics })
        setDataItemsMetrics(
            getStaticMetricDataItems(
                MetricsPaths.PaymentReports,
                (await getCreatedMetrics(MetricsPaths.PaymentReports)) as []
            )
        )
        setListKeysMetrics(
            getStaticMetricDataListKeys(MetricsPaths.PaymentReports)
        )
        setDataItemsGroupings(
            prepareGroupsData(StaticGroupings[MetricsPaths.PaymentReports])
        )
        setListKeysGroupings(
            generateListKeys(
                prepareGroupsData(StaticGroupings[MetricsPaths.PaymentReports])
            )
        )
        break
      }
      case "web": {
        setSelectedMetrics(metrics)
        setSelectedGroupings(groupings)
        setGroupings({ data: groupings })
        setMetrics({ data: metrics })
        setDataItemsMetrics(
            getStaticMetricDataItems(
                MetricsPaths.WebinarReports,
                (await getCreatedMetrics(MetricsPaths.WebinarReports)) as []
            )
        )
        setListKeysMetrics(
            getStaticMetricDataListKeys(MetricsPaths.WebinarReports)
        )
        setDataItemsGroupings(
            prepareGroupsData(StaticGroupings[MetricsPaths.WebinarReports])
        )
        setListKeysGroupings(
            generateListKeys(
                prepareGroupsData(StaticGroupings[MetricsPaths.WebinarReports])
            )
        )
        break
      }
      case "visit": {
        setSelectedMetrics(metrics)
        setSelectedGroupings(groupings)
        setGroupings({ data: groupings })
        setMetrics({ data: metrics })
        setDataItemsMetrics(
            getStaticMetricDataItems(
                MetricsPaths.Universal,
                (await getCreatedMetrics(MetricsPaths.Universal)) as []
            )
        )
        setListKeysMetrics(getStaticMetricDataListKeys(MetricsPaths.Universal))
        setDataItemsGroupings(
            prepareGroupsData(StaticGroupings[MetricsPaths.Universal])
        )
        setListKeysGroupings(
            generateListKeys(
                prepareGroupsData(StaticGroupings[MetricsPaths.Universal])
            )
        )
        break
      }
    }
    setAllDataFetching({ value: true })
  }
  const onSubmit = (values: any) => {
    const displayValue = form.getFieldValue("display")

    const { attribution } =
        attributesDashboard?.attribution === "blocks"
            ? attributes
            : attributesDashboard
    const { type_applications } =
        attributesDashboard?.type_applications === "blocks"
            ? attributes
            : attributesDashboard
    const newBlockId = generateUniqueID()
    const attributionValue =
        typeof attribution === "string" ? [ attribution ] : attribution
    const typeApplicationsValue =
        typeof type_applications === "string"
            ? [ type_applications ]
            : type_applications
    const chartData = {
      details,
      filters: filters,
      attribution: {
        attribution: attributionValue,
        type_applications: typeApplicationsValue,
      },
      chartType,
      // groupings_names,
      // selected_groups_path,
      // selected_metrics_names,
    }
    const isChosenNumberMetric = (displayValue || blockType) === "number"
    if (isChosenNumberMetric && metricsChecked.length === 0) {
      showErrorMessage("Выберите метрику в таблице.")
      return
    }

    setAttributesDashboardBlocks({
      blockId: newBlockId,
      attribution: attributes?.attribution,
      type_applications: attributes?.type_applications,
    })

    setDashboardsBlocksInitialState([ newBlockId ])
    setDashboardsBlocksTableInitialState({
      ids: [ newBlockId ],
      data: {
        groupings,
        metrics,
        metricsChecked,
        groupsChecked,
        totalChecked,
        percentages,
        filtersTable,
      },
    })
    setFilters({ data: filters, blockId: newBlockId })

    setDashboardsBlocksChartState({
      ids: [ newBlockId ],
      data: {
        chartType,
      },
    })
    setDashboardBlockDetails({
      blockId: newBlockId,
      detail: details,
    })
    console.log(values, selectedMetrics, chartData)
    const getHeight = () => {
      const blockTypeValue = displayValue || blockType
      if (blockTypeValue === "chart" && chartType === "pie") {
        return 20
      }
      if (blockTypeValue === "number") {
        return 7
      }
      return 20
    }
    const getWidth = () => {
      const componentType = displayValue || blockType
      if (componentType === "number") {
        return 2
      }
      return calculateWidth(selectedMetrics.length, 12)
    }

    const newComponent: DashboardComponent = {
      title: values.title,
      componentType: displayValue || blockType,
      layoutData: {
        i: newBlockId,
        x: 0,
        y: Infinity,
        minH: blockType === "heading" ? 2 : 7,
        minW: 2,
        ...(() => {
          if (blockType === "heading") {
            const textDimensions = measureTextDimensions(values.title)
            const { w, h } = convertToGridUnits(
                textDimensions.width,
                textDimensions.height,
                140,
                10
            )
            return { w, h }
          } else {
            return {
              w: getWidth(),
              h: getHeight(),
            }
          }
        })(),
      },
      data: {
        blockId: newBlockId,
        selectedMetrics,
        metricsChecked,
        groupsChecked,
        selectedGroupings,
        heading,
        ...values,
        date: form.getFieldValue("date"),
        ...chartData,
      },
    }
    onAddBlock(newComponent)
    setVisible({ visible: false, blockType: "" })
  }

  const handleValueChange = (key: string, value: string) => {
    form.setFieldValue(key, value)

    if (key === "display") {
      handleSelectChange(value)
    }
    if (key === "date") {
      handlePeriodDateChange(value)
    }
  }

  return (
      <Modal
          title={null}
          open={visible}
          style={{
            top: 0,
          }}
          className={"addBlockModal modal-settings"}
          width={"78%"}
          footer={null}
          maskClosable={false}
          onCancel={handleCloseModal}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ width: "100%", marginRight: "24px" }}>
            <Form onFinish={onSubmit} layout={"vertical"} form={form}>
              <div
                  style={{
                    marginTop: "16px",
                    width: "100%",
                    display: "flex",
                    columnGap: "8px",
                  }}
              >
                <Form.Item
                    name="title"
                    label={
                      currentSelect === "heading" ? "Заголовок" : "Название блока"
                    }
                >
                  {blockType === "heading" ? (
                      <Input
                          value={heading}
                          onChange={(event) => setHeading(event.target.value)}
                      />
                  ) : (
                      <Input />
                  )}
                </Form.Item>
                <Form.Item
                    style={{ width: "60%" }}
                    name="description"
                    label="Описание"
                >
                  <Input />
                </Form.Item>
              </div>
              {blockType !== "heading" && (
                  <>
                    <div
                        style={{
                          display: "flex",
                          columnGap: "20px",
                          marginBottom: "20px",
                        }}
                    >
                      {displayItems.map((item, index) => (
                          <FormManualSelect
                              key={index}
                              id={item.id}
                              name={item.name}
                              selectValues={item.selectValues}
                              description={item?.description}
                              icon={item.icon}
                              form={form}
                              handleValueChange={handleValueChange}
                          />
                      ))}
                      {(form.getFieldValue("display") === "chart" ||
                          form.getFieldValue("display") === "table") && (
                          <>
                            {form.getFieldValue("display") === "chart" && (
                                <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      rowGap: "8px",
                                      alignItems: "center",
                                    }}
                                >
                                  <span style={{marginRight: "5px"}}>Тип графика:</span>
                                  <ButtonsType/>
                                </div>
                            )}
                          </>
                      )}
                    </div>
                  </>
              )}
              {currentSelect !== "" && currentSelect !== "heading" && (
                  <div style={{marginBottom: "20px"}}>
                    <Params
                        attributionItems={attrItems}
                        detailsItems={chartDetails}
                        filtersFilter={[
                          "Пользователи",
                          "Регистрации",
                          "Заявки",
                          "Заказы",
                          "Платежи",
                          "Вебинары",
                        ]}
                        isFilterVisible={false}
                    />
                  </div>
              )}

              {currentSelect === "heading" || blockType === "heading" ? null : (
                  <>
                    <div
                        className="universal-table-settings"
                        style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <div style={{ display: "flex", columnGap: "4px" }}>
                        <Button onClick={() => setIsBasicGroupingsVisible(true)}>
                          Группировки
                        </Button>
                        <Tooltip
                            title={
                              <div
                                  className="page-title-tooltip-text"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                        "Группировка — это возможность сгруппировать регистрации пользователей по определённым условиям и посмотреть их данные по 60 метрикам. В SCANе около 70 различных группировок. <a href=\"https://help.scananalytics.ru/?p=634\" rel=\"noopener noreferrer\" target=\"_blank\">Подробнее</a>.",
                                  }}
                              ></div>
                            }
                            color="#fff"
                            zIndex={9999}
                            trigger="click"
                            overlayClassName="page-title-tooltip"
                        >
                          <QuestionCircleOutlined
                              style={{ color: "#ccc", fontSize: 14, marginLeft: 10 }}
                          />
                        </Tooltip>
                      </div>
                      <div style={{ display: "flex", columnGap: "20px" }}>
                        <Button onClick={() => setIsBasicMetricsVisible(true)}>
                          Метрики
                        </Button>
                        <Tooltip
                            placement="bottom"
                            title={
                              <div
                                  className="page-title-tooltip-text"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                        "Метрики — это показатель выбранных группировок. В сервисе около 60 разных показателей. <a href=\"https://help.scananalytics.ru/?p=636\" rel=\"noopener noreferrer\" target=\"_blank\">Подробнее<a/>.",
                                  }}
                              ></div>
                            }
                            color="#fff"
                            zIndex={9999}
                            trigger="click"
                            overlayClassName="page-title-tooltip"
                        >
                          <QuestionCircleOutlined
                              style={{ color: "#ccc", fontSize: 14 }}
                          />
                        </Tooltip>
                      </div>
                    </div>
                    <div
                        className={"table-modal"}
                        style={{ overflowX: "auto", paddingRight: "20px" }}
                    >
                      {currentSelect && <MemoizedTable display={currentSelect} />}
                    </div>
                  </>
              )}
            </Form>
          </div>
          {currentSelect !== "heading" && (
              <div
                  style={{
                    width: "100%",
                    ...(currentSelect === "chart"
                        ? { paddingLeft: "4%", paddingRight: "4%" }
                        : { display: "flex" }),
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#D0D0D0",
                    borderTop: "1px solid black",
                    marginLeft: "-25px",
                    flex: 1,
                    paddingTop: "4%",
                    flexDirection: "column",
                  }}
              >
                <p
                    style={{
                      alignSelf: "flex-start",
                      marginLeft: "20px",
                      fontWeight: "600",
                      fontSize: "16px",
                    }}
                >
                  Вид блока
                </p>
                <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      columnGap: "12px",
                      marginBottom: "20px",
                    }}
                >
                  <Button
                      style={{ border: "1px solid black" }}
                      onClick={() => setIsBlockVisible((prev) => !prev)}
                      type={"primary"}
                  >
                    {isBlockVisible ? "Скрыть блок" : "Показать блок"}
                  </Button>
                  <Button
                      style={{ border: "1px solid black" }}
                      type={"primary"}
                      onClick={() => form.submit()}
                      htmlType="submit"
                      danger
                  >
                    Сохранить и закрыть
                  </Button>
                  <Button
                      onClick={handleCloseModal}
                      style={{ border: "1px solid black" }}
                  >
                    Закрыть не сохраняя
                  </Button>
                </div>
                {isBlockVisible && (
                    <div
                        style={{
                          textAlign: "center",
                          ...(currentSelect === "table"
                              ? { width: "90%", paddingLeft: "24px", background: "white" }
                              : {}),
                        }}
                    >
                      <Form.Item>{renderView}</Form.Item>
                    </div>
                )}
              </div>
          )}
          <div style={{ marginTop: "20px" }}>
            <Button
                type="primary"
                style={{ marginBottom: "10px" }}
                onClick={() => form.submit()}
                htmlType="submit"
            >
              Добавить
            </Button>
          </div>
        </div>
        {isBasicMetricsVisible && (
            <ModalSettings
                dataItems={dataItemsMetrics}
                listKeys={listKeysMetrics}
                isVisible={isBasicMetricsVisible}
                setVisible={(value) => {
                  setIsBasicMetricsVisible(value),
                  selectedGroupings?.length > 0 &&
                  setAllDataFetching({ value: true })
                }}
                title={"Метрики"}
                selected={selectedMetrics}
                handleListChange={(newState) => {
                  handleListChange(newState, setSelectedMetrics, setMetrics)
                }}
                setSelected={setSelectedMetrics}
            />
        )}
        {isBasicGroupingsVisible && (
            <ModalSettings
                dataItems={dataItemsGroupings}
                listKeys={listKeysGroupings}
                isVisible={isBasicGroupingsVisible}
                setVisible={(value) => {
                  setIsBasicGroupingsVisible(value)
                  if (value && selectedMetrics?.length > 0) {
                    setAllDataFetching({ value: true })
                  }
                }}
                title={"Группировки"}
                selected={selectedGroupings}
                handleListChange={(newState) => {
                  handleListChange(newState, setSelectedGroupings, setGroupings)
                }}
                setSelected={setSelectedGroupings}
            />
        )}
      </Modal>
  )
}

export default AddBlockModal

