import { groupMapping } from "../../shared/common/constants/reports";

interface SelectedInput {
  selected: { dataKey: string }[];
}

interface InputStates {
  [key: string]: SelectedInput;
}

interface ConverseData {
  inputStates: InputStates;
  selectedAction: {
    id: string;
    label: string;
  };
  constant_argument: number | null | undefined;
}

import React, { Dispatch, SetStateAction } from "react";

import { FormInstance, message, UploadProps } from "antd";
import { FilterDropdownProps } from "antd/lib/table/interface";
import moment, { Moment } from "moment/moment";

import { summaryMetrics } from "./data";
import { showErrorMessage, showSuccessNotification } from "./ui";
import { ISelectedSchool } from "../../app/providers/redux/slices/currentSchool/types";
import { ITableDataGeneric } from "../../app/providers/redux/slices/reports/table/types";
import { uploadsSchoolApi } from "../../shared/common/api/endpoints/uploadsSchoolApi";
import { routeNames } from "../../shared/common/constants/routes";
import { useTypedSelector } from "../../shared/common/hooks/useTypedSelector";
import { PDFDocument } from "pdf-lib";
import { PagesPathnames } from "../../app/types/ad";
import { IChartData } from "../../app/providers/redux/slices/reports/chart/types";
import UniversalChart from "../../shared/common/components/reports/un-chart/Chart";
import AdChart from "../../shared/common/components/reports/ad-chart/Chart";
import WebinarChart from "../../shared/common/components/reports/web-chart/Chart";
import PaymentChart from "../../shared/common/components/reports/payment-chart/Chart";
import VisitsChart from "../../shared/common/components/reports/ad-chart/Chart";
import OrderChart from "../../shared/common/components/reports/order-chart/Chart";
import { defaultGroupingsAd } from "../../pages/ad-reports";
import {
  defaultGroupingAd,
  defaultMetricAd,
  defaultMetricsAd,
} from "../../pages/ad-reports/constants";
import {
  defaultGroupingNumber,
  defaultGroupingsOrder,
  defaultMetricsOrder, defaultMetricsOrderNumber,
} from "../../pages/order-reports/OrderReports";
import {
  defaultGroupingPayment,
  defaultGroupingsPayment, defaultMetricsOrderPayment,
  defaultMetricsPayment,
} from "../../pages/payment-reports/PaymentReports";
import {
  defaultGroupingsWebinar, defaultGroupingWeb,  defaultMetricsWeb,
  defaultMetricsWebinar,
} from "../../pages/webinar-reports/WebinarReports";
import {
  defaultGroupingReg,
  defaultGroupings,
  defaultMetrics, defaultMetricsReg,
} from "../../app/providers/redux/slices/reports/table/tableSlice";
import {
  defaultGroupingsVisits,
  defaultMetricsVisits,
} from "../../pages/reports/visits/Visits";

interface Period {
  start: string;
  end: string;
  interval: string;
}
interface SeriesCoords {
  x: string;
  y: string;
  category: string;
}
interface Series {
  name: string;
  data: SeriesCoords[];
  dataKey: string;
}
interface AdGroupings {
  name: string;
  key: string;
  dataKey: string;
}
interface RowData {
  key?: string;
  dataKey?: string;
  children: RowData[];
  id: number;
  group: string;
  clientData: {
    downloaded: boolean;
    pathLabel: string[];
    path: number[];
  };
}
export interface Column {
  title: string;
  dataIndex: string;
  key: string;
  filters?: any;
  sorter?: any;
  onFilter?: (value: any, record: any) => boolean;
  render?: (value: any, record: any) => JSX.Element;
  filterDropdown?: (props: FilterDropdownProps) => React.ReactNode;
}
export const endsWithNumber = (dataKey: string): boolean => {
  return /\d$/.test(dataKey);
};

export const generateUniqueID = () => {
  return `v4-${Date.now()}-${Math.floor(Math.random() * (9e12 - 1)) + 1e12}`;
};

export interface TableRow {
  [key: string]: string | number | JSX.Element | undefined;
}
interface TreeNode {
  label: string;
  children?: TreeNode[];
  dataKey?: string;
}
export const getPlanSeries = (
  el: { label: string; dataKey: string; data: any; plan: number },
  details: string,
  isCumulative = false
) => {
  let cumulativeSum = 0;

  return {
    name: `План (${el.label})`,
    dataKey: el.dataKey,
    data: el.data.map((dataEl: { plan: number; x: string }) => {
      let yForPlan = Number(el.plan);
      const [monthName, yearStr] = dataEl.x.split(" ");
      const monthIndex = [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь",
      ].indexOf(monthName);
      const daysInMonth = new Date(
        Number(yearStr),
        monthIndex + 1,
        0
      ).getDate();
      const year = parseInt(yearStr, 10);

      switch (details) {
        case "d":
          if (isCumulative) {
            cumulativeSum += yForPlan;
            yForPlan = cumulativeSum;
          }
          break;
        case "w":
        case "d-w":
          yForPlan *= 7;
          if (isCumulative) {
            cumulativeSum += yForPlan;
            yForPlan = cumulativeSum;
          }
          break;
        case "m":
          yForPlan *= daysInMonth;
          if (isCumulative) {
            cumulativeSum += yForPlan;
            yForPlan = cumulativeSum;
          }
          break;
        case "q":
          yForPlan *= 3 * 30;
          if (isCumulative) {
            cumulativeSum += yForPlan;
            yForPlan = cumulativeSum;
          }
          break;
        case "y":
          const isLeapYear =
            year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
          yForPlan *= isLeapYear ? 366 : 365;
          if (isCumulative) {
            cumulativeSum += yForPlan;
            yForPlan = cumulativeSum;
          }
          break;
      }

      return {
        x: isCumulative ? dataEl.x : "",
        y: yForPlan,
        category: `План (${el.label})`,
      };
    }),
  };
};
export const calculateDashedLines = (_series: any, isCompare: boolean) => {
  if (_series.some((el: { name: string }) => el?.name?.startsWith("План"))) {
    return _series.map((el: any) => {
      return el?.name?.startsWith("План") ? 4 : 0;
    });
  }
  if (!isCompare) return 0;
  let result;

  try {
    if (_series.length > 0) {
      result = _series.map((axis: any) => {
        if (typeof axis === "number") return 0;

        if (axis.name.includes("(2)")) {
          return 0;
        } else {
          return 4;
        }
      });
    } else {
      result = 0;
    }
  } catch (e) {
    result = 0;
  }

  return result;
};
export const parseDate = (dateString: string): Date => {
  const formatDateString = () => {
    const parts = dateString.split(".");
    if (parts.length === 3) {
      const day = parseInt(parts[0]);
      const month = parseInt(parts[1]) - 1;
      const year = parseInt(parts[2]);
      if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
        return new Date(year, month, day);
      }
    }
  };
  return dateString.includes("-")
    ? new Date(dateString.replace(/-/g, "."))
    : (formatDateString() as Date);
};
export const filterTree = (nodes: TreeNode[], terms: string[]): TreeNode[] => {
  const filteredNodes = nodes.filter((node) => {
    if (typeof node?.dataKey === "string" && terms.includes(node?.dataKey)) {
      return false;
    }
    if (node.children && node.children.length) {
      node.children = filterTree(node.children, terms);
    }
    return true;
  });

  return filteredNodes;
};

export const getLinkPath = (routeNamesPath: string) => {
  const { userAccess } = useTypedSelector((state) => state.currentSchool);
  const role = useTypedSelector((state) => state.currentUser.role);
  const allowList = [
    "/source/school",
    "/source/addschool",
    "/users/billing",
    "/users/profile",
    "users/partner",
  ];
  if (
    !userAccess?.telegram_connect &&
    !localStorage.getItem("access_admin") &&
    role !== "superadmin"
  ) {
    return routeNames.telegramConnect;
  }
  /*
  if(!userAccess?.status){
    return allowList.some((path)=>routeNamesPath.includes(path)) ? routeNamesPath : routeNames.bill
  }
   */
  return routeNamesPath;
};
export const getFiltersWithoutSegment = (
  filters: any[],
  segmentFilters: any
) => {
  if (!Array.isArray(filters)) {
    return [];
  }
  if (!segmentFilters || segmentFilters?.length === 0) {
    return filters;
  }
  return filters?.filter(
    (filter: any) =>
      !segmentFilters?.some(
        (el: any) => JSON.stringify(el) === JSON.stringify(filter)
      )
  );
};
// export const getFiltersWithoutSegment = (filters:any[],currentReport:any) => {
//   if(!currentReport?.values?.segment?.values?.filters || currentReport?.values?.segment?.values?.filters?.length === 0){
//     return filters
//   }
//   return filters?.filter((filter:any) => !currentReport?.values?.segment?.values?.filters?.some((el:any)=>JSON.stringify(el) === JSON.stringify(filter)))
// }
export const clearSearchParams = () => {
  const url = new URL(window.location.href);
  url.search = "";

  window.history.pushState({ path: url.href }, "", url.href);
};

export const normalizeCreatedMetrics = (metrics: any[]) => {
  const copiedMetrics = [...metrics];

  copiedMetrics.sort((a, b) => {
    const metricNameA = a.name.toUpperCase();
    const metricNameB = b.name.toUpperCase();
    if (metricNameA < metricNameB) {
      return -1;
    }
    if (metricNameA > metricNameB) {
      return 1;
    }
    return 0;
  });

  return copiedMetrics.map((el) => {
    let background,
      fillingConditions,
      isFilling,
      unit,
      plan,
      base_metric_name,
      metric_formula;
    try {
      const visualParams = JSON.parse(el.visual_parameters);
      plan = visualParams?.plan;
      isFilling = visualParams?.isFilling;
      unit = visualParams?.unit;
      background = visualParams?.backgroundColor;
      fillingConditions = visualParams?.fillingConditions;
      base_metric_name = visualParams?.base_metric_name;
      metric_formula = visualParams?.metric_formula;
    } catch (err) {
      console.log(err);
    }
    return {
      ...el,
      dataKey: el.metric_name,
      background,
      fillingConditions,
      isFilling,
      unit,
      plan,
      base_metric_name,
      metric_formula,
    };
  });
};

interface GroupMapping {
  [key: string]: string[];
}

interface Series {
  base_metric_name: string;
}

export const isMoment = (value: any): value is moment.Moment => {
  return moment.isMoment(value);
};

export const formatPeriod = (
  period: { start: string | moment.Moment; end: string | moment.Moment },
  format: string
) => {
  return {
    start: isMoment(period.start)
      ? period.start.format(format)
      : moment(period.start).format(format),
    end: isMoment(period.end)
      ? period.end.format(format)
      : moment(period.end).format(format),
  };
};

interface Series {
  base_metric_name: string;
}

export const findGroup = (
  groupMapping: GroupMapping,
  base_metric_name: string,
  cumulativeChart: boolean
): string | undefined => {
  const modifiedMetricName = base_metric_name.replace(
    /(_\d+)?_cumulative$/g,
    ""
  );
  const metricName = cumulativeChart
    ? modifiedMetricName
    : base_metric_name.replace(/(_\d+)/g, "");

  return Object.keys(groupMapping).find((groupName) => {
    return groupMapping[groupName].includes(metricName);
  });
};
export const handleCustomMetricGroup = (
  series: { base_metric_name: string; metric_formula: string; name: string },
  cumulativeChart: boolean
) => {
  if (series?.base_metric_name) {
    return findGroup(groupMapping, series.base_metric_name, cumulativeChart);
  }
  if (series?.metric_formula) {
    const isConversation =
      series.metric_formula.split("/")[0] === "calculate_conversion";
    if (isConversation) {
      return "sixthGroup";
    } else {
      const independentMetricsFormulas = ["multiply", "divide"];
      if (
        independentMetricsFormulas.some((el) =>
          series.metric_formula.split("/")[0].includes(el)
        )
      ) {
        return series.name;
      } else {
        return findGroup(
          groupMapping,
          series.metric_formula.split("/")[1],
          cumulativeChart
        );
      }
    }
  }
  return "customMetricGroup";
};
export const getPieTooltip = (series: IChartData[], value: number) => {
  const summaryValue = series?.reduce((acc, val) => acc + val?.value, 0);

  const getPercentage = (value: number, total: number) => {
    if (total === 0) return 0;
    return ((value / total) * 100).toFixed(2);
  };
  const percentage = getPercentage(value, summaryValue);
  return `${value} (${percentage}% от ${summaryValue})`;
};

export const copyToClipboard = async (
  columns: Column[],
  tableData: TableRow[]
) => {
  const headers =
    columns
      .filter((col) => col.title !== "Действия")
      .map((col) => col.title)
      .join("\t") + "\n";

  const rows = tableData
    .map((row) =>
      columns
        .filter((col) => col.title !== "Действия")
        .map((col) => row[col.dataIndex] || "")
        .join("\t")
    )
    .join("\n");

  const tsv = headers + rows;

  try {
    await navigator.clipboard.writeText(tsv);
    message.success({
      content: "Скопировано",
      style: {
        marginTop: "20%",
      },
    });
  } catch (err) {
    message.error({
      content: "Ошибка при копировании данных",
      style: {
        marginTop: "20%",
      },
    });
  }
};
export const isNumeric = (value: string) => {
  try {
    return /^\d+$/.test(value.trim());
  } catch (err) {
    return false;
  }
};
export const getMetricFormula = (converseData: ConverseData): string | null => {
  const sign = "/";
  if (typeof sign === "string" && sign?.length > 1) {
    return sign;
  }

  if (sign === null) {
    return null;
  }

  const dataKeysArray = Object.values(converseData.inputStates).map(
    (input) => input.selected[0]?.dataKey
  );

  const formula = dataKeysArray.join(sign);

  return `${converseData.selectedAction.id}${sign}${formula}${
    converseData.constant_argument ? converseData.constant_argument : ""
  }`;
};

export const getUniqueEmailsForFilters = (key: string, tableData: any[]) => {
  if (Array.isArray(tableData)) {
    const emailsSet = new Set(tableData.map((item) => item[key]));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return [...emailsSet].map((email) => ({ text: email, value: email }));
  } else {
    return [];
  }
};
const commonUploadProps: UploadProps = {
  name: "file",
  customRequest: () => null,
  fileList: [],
};
export const getSummaryOfGroupings = (groupsChecked: any, data: any) => {
  let result: any = {};
  const getObjectKey = (obj: any) => {
    return JSON.stringify(obj);
  };
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const uniqueGroupsChecked = [...new Set(groupsChecked.map(getObjectKey))].map(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    JSON.parse
  );

  for (const el of uniqueGroupsChecked) {
    if (
      el?.ids?.length > 1 &&
      uniqueGroupsChecked.some(
        (parentElement) => Number(parentElement.pathStr) === el.ids[0]
      )
    ) {
      return;
    } else {
      const row = findObjectForSummaryGroup(data, {
        id: el.ids[el.ids.length - 1],
        group: el.label,
        ids: el.ids,
      });
      if (row) {
        const notMetrics = [
          "id",
          "group",
          "pathStr",
          "pathStr2",
          "children",
          "clientData",
          "uniqueKey",
        ];
        for (let [key, value] of Object.entries(row)) {
          if (!notMetrics.includes(key)) {
            if (Object.hasOwn(result, key)) {
              result[key] = Number(result[key]) + Number(parseFloat(value));
            } else {
              result[key] = Number(parseFloat(value));
            }
          }
        }
      }
    }
  }

  const countValues = uniqueGroupsChecked.length;
  const setResult = (arr: string[]) => {
    for (let key in result) {
      !arr.includes(key)
        ? (result[key] = +parseFloat(
            (result[key] / countValues).toString()
          ).toFixed(2))
        : null;
    }
  };

  switch (window.location.pathname) {
    case "/users/universal": {
      setResult(summaryMetrics);
      break;
    }
    case "/users/order-reports": {
      const orderMetrics = summaryMetrics.map((el) => el + "_2");
      setResult(orderMetrics);
      break;
    }
    case "/users/payment-reports": {
      const paymentMetrics = summaryMetrics.map((el) => el + "_3");
      setResult(paymentMetrics);
      break;
    }
    case "/users/ad-reports": {
      setResult(summaryMetrics);
      break;
    }
    case "/users/webinar-reports": {
      const webMetrics = summaryMetrics.map((el) => el + "_4");
      console.log(webMetrics);
      setResult(webMetrics);
      break;
    }
  }
  return result;
};
export const calculateFirstBlock = (period2: Period): Period => {
  const startDate2 = moment(period2.start);
  const endDate2 = moment(period2.end);

  const daysDifference = endDate2.diff(startDate2, "days");

  const firstBlockStart = startDate2.subtract(daysDifference + 1, "days");
  const firstBlockEnd = startDate2.clone().add(daysDifference, "days");

  return {
    start: firstBlockStart.toISOString(),
    end: firstBlockEnd.toISOString(),
    interval: "",
  };
};
export const findSmallestYForSeriesName = (
  seriesName: string,
  newSeries: any
) => {
  const series: any = newSeries.find((item: any) => item.name === seriesName);
  if (series) {
    let smallestY: any = null;

    series.data.forEach((entry: any) => {
      if (smallestY === null || entry.y < smallestY) {
        smallestY = entry.y;
      }
    });

    return smallestY;
  }
};

export const getUploadProps = (
  setLoadingFn: Dispatch<SetStateAction<boolean>>,
  type: string,
  selectedSchool: ISelectedSchool | null,
  account_id = 0
) => {
  const props: UploadProps = {
    ...commonUploadProps,
    customRequest: (options) => {
      if (selectedSchool) {
        setLoadingFn(true);
        uploadsSchoolApi
          .uploadFile(
            parseInt(selectedSchool.id),
            type,
            options.file,
            account_id
          )
          .then((response) => {
            if (response.status === 200) {
              showSuccessNotification("Файл успешно загружен.");
            }
          })
          .catch(() => {
            showErrorMessage(
              "Ошибка при загрузке файла, повторите попытку позже."
            );
          })
          .finally(() => {
            setLoadingFn(false);
          });
      }
    },
  };
  return props;
};
export const switchInterval = (
  interval: string
): { start: Moment | undefined; end: Moment | undefined } => {
  const startTime = { hour: 0, minute: 0, second: 0 };
  const endTime = { hour: 23, minute: 59, second: 59 };
  let start, end;
  switch (interval) {
    case "today":
      start = moment().set(startTime);
      end = moment().set(endTime);
      break;
    case "yesterday":
      start = moment().subtract(1, "days").set(startTime);
      end = moment().subtract(1, "days").set(endTime);
      break;
    case "thisweek":
      start = moment().isoWeekday(1).set(startTime);
      end = moment().set(endTime);
      break;
    case "lastweek":
      start = moment().subtract(1, "week").isoWeekday(1).set(startTime);
      end = moment().subtract(1, "week").isoWeekday(7).set(endTime);
      break;
    case "thismonth":
      start = moment().startOf("month").set(startTime);
      end = moment().set(endTime);
      break;
    case "lastmonth":
      start = moment().subtract(1, "month").startOf("month").set(startTime);
      end = moment().subtract(1, "month").endOf("month").set(endTime);
      break;
    case "thisquart":
      start = moment().startOf("quarters").set(startTime);
      end = moment().set(endTime);
      break;
    case "lastquart":
      start = moment()
        .subtract(1, "quarters")
        .startOf("quarters")
        .set(startTime);
      end = moment().subtract(1, "quarters").endOf("quarters").set(startTime);
      break;
    case "thisyear":
      start = moment().startOf("year").set(startTime);
      end = moment();
      break;
    case "lastyear":
      start = moment().subtract(1, "year").startOf("year").set(startTime);
      end = moment().subtract(1, "year").endOf("year").set(startTime);
      break;
    case "last7days":
      start = moment().subtract(6, "days").set(startTime);
      end = moment().set(endTime);
      break;
    case "last14days":
      start = moment().subtract(13, "days").set(startTime);
      end = moment().set(endTime);
      break;
    case "last30days":
      start = moment().subtract(29, "days").set(startTime);
      end = moment().set(endTime);
      break;
    case "last365days":
      start = moment().subtract(365, "days").set(startTime);
      end = moment().set(endTime);
      break;
    case "allthetime":
      start = moment().year(2000).set(startTime);
      end = moment().set(endTime);
      break;
  }
  return { start, end };
};
export const findNameWithMinSum = (arr: any) => {
  const sums = arr.map((item: any) => {
    const sum = item.data.reduce((total: any, obj: any) => {
      const num = parseFloat(obj.y);
      return !isNaN(num) ? total + num : total;
    }, 0);
    return { name: item.name, sum };
  });

  const minSumElement = sums.reduce((min: any, item: any) =>
    item.sum < min.sum ? item : min
  );
  return minSumElement.name;
};

export const isDifferenceGreaterThanThresholds = (series: any) => {
  let maxValues: number[] = [];

  series.forEach((item: any) => {
    const maxValue = Math.max(
      ...item.data.map((dataItem: any) => Number(dataItem.y))
    );
    maxValues.push(maxValue);
  });

  const maxDifference = Math.max(...maxValues) - Math.min(...maxValues);
  return maxDifference > 40000;
};
export const isDifferenceGreaterThanThreshold = (series: any) => {
  let maxValues: number[] = [];

  series.forEach((item: any) => {
    const maxValue = Math.max(
      ...item.data.map((dataItem: any) => Number(dataItem.y))
    );
    maxValues.push(maxValue);
  });

  const maxDifference = Math.max(...maxValues) - Math.min(...maxValues);
  return maxDifference > 10000;
};

export const convertYToNumber = (arr: any) => {
  return arr.map((item: any) => ({
    ...item,
    data: item.data.map((dataItem: any) => ({
      ...dataItem,
      y: Number(dataItem.y),
    })),
  }));
};
export const getAverageSeries = (
  newSeries: Series[],
  metricNames: any = []
) => {
  const averageObject: any = {
    name: "Среднее (Выбранные)",
    data: [],
  };

  const sumByX: any = {};
  for (const obj of newSeries) {
    if (!obj.name.includes("Всего")) {
      for (const item of obj.data) {
        const { x, y } = item;

        if (x in sumByX) {
          sumByX[x] += parseFloat(y);
        } else {
          sumByX[x] = parseFloat(y);
        }
      }
    }
  }
  for (const x in sumByX) {
    let isSummary = false;
    let result;
    newSeries.forEach((element) => {
      isSummary = summaryMetrics.includes(element.dataKey);
    });
    const getCategory = () => {
      if (metricNames.length === 1) {
        return `${metricNames[0].label} (Итого и среднее выбранных)`;
      }
      return "Итого и среднее выбранных";
    };
    if (!isSummary) {
      const averageY =
        sumByX[x] /
        (includesTotal(newSeries) ? newSeries.length - 1 : newSeries.length);
      result = +averageY.toFixed(2).toString();
    } else {
      result = sumByX[x].toFixed(2).toString();
    }

    averageObject.data.push({ x: x, y: result, category: getCategory() });
    averageObject.name = getCategory();
  }
  return averageObject;
};

export const includesTotal = (arr: any) => {
  let result = false;
  arr.forEach((el: any) => {
    if (el.name.includes("(Всего)")) {
      result = true;
    }
  });
  return result;
};
export const modifyPdfFileName = async (
  pdfBytes: ArrayBuffer,
  newFileName: string
) => {
  const pdfDoc = await PDFDocument.load(pdfBytes);

  pdfDoc.setTitle(newFileName);

  const modifiedPdfBytes = await pdfDoc.save();

  return modifiedPdfBytes;
};
export const handleEmptyDescription = (
  metricsChecked: any[],
  groupsChecked: any[],
  errorMessage: string
) => {
  if (metricsChecked?.length === 0 || groupsChecked?.length === 0) {
    return "Для графика выберите метрику и группировку";
  } else {
    return errorMessage;
  }
};
export const findObjectByLabelId = (
  arr: RowData[],
  id: number,
  label: string
): RowData | null => {
  for (let obj of arr) {
    if (obj.id === id && obj.group === label) {
      return obj;
    }

    if (obj.children && obj.children.length > 0) {
      const result = findObjectByLabelId(obj.children, id, label);
      if (result) {
        return result;
      }
    }
  }

  return null;
};
export const findObjectById = (
  arr: RowData[] | any[],
  id: number | string
): RowData | any | null => {
  for (let obj of arr) {
    if (obj.id === id) {
      return obj;
    }

    if (obj.children && obj.children.length > 0) {
      const result = findObjectById(obj.children, id);
      if (result) {
        return result;
      }
    }
  }

  return null;
};
function arraysEqual(a: number[], b: number[]) {
  if (a.length !== b.length) return false;

  for (let i = 0; i < a.length; i++) {
    if (a[i] != b[i]) return false;
  }

  return true;
}
export const splitNameAndId = (inputString: string) => {
  if (!inputString) {
    return ["", ""];
  }

  if (inputString.trim().startsWith("(") && inputString.trim().endsWith(")")) {
    const regex = /^\((\d+)\|([^)]+)\)$/;
    const match = inputString.trim().match(regex);
    if (match) {
      const id = match[1].trim();
      const name = match[2].trim();
      return [name, `(${id})`];
    }
  }

  const regex = /^(.*)\s+\(([^()|]+)(?:\|[^()]+)?\)$/;
  const match = inputString.match(regex);

  if (match) {
    const nameWithIdentifier = match[1].trim();
    const id = match[2].trim();
    console.log(match, "here match");
    return [nameWithIdentifier, `(${id})`];
  }

  return [inputString, ""];
};

const findObjectForSummaryGroup = (
  arr: RowData[],
  data: { id: string | number; group: string; ids: number[] }
): RowData | null => {
  for (let obj of arr) {
    if (obj.id == data.id && arraysEqual(obj.clientData.path, data.ids)) {
      return obj;
    }

    if (obj.children && obj.children.length > 0) {
      const result = findObjectForSummaryGroup(obj.children, data);
      if (result) {
        return result;
      }
    }
  }

  return null;
};
export const findMetricById = (arr: RowData[], id: string): RowData | null => {
  for (let obj of arr) {
    if (obj.dataKey === id) {
      return obj;
    }

    if (obj.children && obj.children.length > 0) {
      const result = findMetricById(obj.children, id);
      if (result) {
        return result;
      }
    }
  }

  return null;
};
export const getAdGroupings = (
  groupings: AdGroupings[],
  listKeysGroupings: AdGroupings[]
) => {
  let adGroupings: ([] | { name: string; dataKey: string; key: string })[] = [];
  listKeysGroupings.forEach(
    (el: { name: string; key: string; dataKey: string }) => {
      groupings.forEach(
        (element: { name: string; key: string; dataKey: string }) => {
          if (element.dataKey === el.dataKey) {
            adGroupings.push(el);
          }
        }
      );
    }
  );
  return adGroupings;
};
export const sortDataRecursive = (
  data: ITableDataGeneric[],
  sortTable: [key: string]
): ITableDataGeneric[] => {
  const sortValue = Object.values(sortTable)[0];
  const sortKey = Object.keys(sortTable)[0];

  return [...data]
    .sort((a, b) => {
      if (sortValue === "ASC") {
        return a[sortKey] - b[sortKey];
      } else if (sortValue === "DESC") {
        return b[sortKey] - a[sortKey];
      } else {
        return 0;
      }
    })
    .map((item) => {
      if (item.children && item.children.length > 0) {
        return {
          ...item,
          children: sortDataRecursive(item.children, sortTable),
        };
      } else {
        return item;
      }
    });
};
export const isFirstGrouping = <T extends RowData>(
  mainParent: T,
  parent: T | null,
  children: T
): boolean => {
  if (mainParent?.id === children?.id && mainParent?.group === children?.group)
    return true;
  if (parent && Array.isArray(parent.children) && parent.children.length > 0) {
    return (
      (children.id === parent.children[0].id &&
        children.group === parent.children[0].group) ||
      isFirstGrouping(mainParent, parent.children[0], children)
    );
  }
  return false;
};

export const getGroupTitle = (
  rowData: RowData,
  groupings: ITableDataGeneric[]
) => {
  const groupingIndex = rowData?.clientData?.pathLabel?.length - 1;
  const groupingName = groupings[groupingIndex]?.name;
  return groupingName || "";
};
export const isObjectEmpty = (objectName: Object | null | undefined) => {
  if (objectName == null) {
    return true;
  }
  return Object.keys(objectName).length === 0;
};
export const isAdvert = () => {
  return location.pathname === "/advert/rate"
    ? { justifyContent: "flex-start" }
    : undefined;
};
export const findNodeByValue = (nodes: any, targetValue: any): any => {
  for (const node of nodes) {
    if (node.value === targetValue) {
      return node;
    }
    if (node.children) {
      const found = findNodeByValue(node.children, targetValue);
      if (found) {
        return found;
      }
    }
  }
  return null;
};
export const validateUtmContent = (_: unknown, value: string) => {
  if (!value) {
    // Если поле пустое, валидация проходит успешно
    return Promise.resolve();
  }

  const isValid = /^[0-9]+$/.test(value.toString());
  if (isValid && value.toString().length <= 18) {
    // Введены только цифры и длина не превышает 18 символов
    return Promise.resolve();
  } else {
    // Введены неверные данные
    return Promise.reject(
      "Пожалуйста, введите только цифры и не более 18 символов"
    );
  }
};
export const serializeState = (stateObject: object | string) => {
  const serializedState = JSON.stringify(stateObject);
  return encodeURIComponent(serializedState);
};
export const deserializeState = (queryString: string) => {
  const decodedState = decodeURIComponent(queryString);
  return JSON.parse(decodedState);
};
export const updateQueryParams = (stateObject: any, parameter: string) => {
  const url = new URL(window.location.href);
  return;
  // const clearParams = () => {
  //   url.searchParams.delete("params")
  //   localStorage.removeItem("location")
  //   window.history.pushState({ path: url.href }, '', url.href);
  // }
  // if(!window.location.pathname.includes("reports") || window.location.pathname === '/dashboards' || window.location.pathname.includes("crm")){
  //   clearParams()
  //   return
  // }
  //
  // const currentParams = url.searchParams.get('params')
  // const paramsObject = currentParams ? JSON.parse(currentParams) : {}
  // const location = localStorage.getItem("location") || ""
  //
  // if(location?.length > 0 && location !== window.location.pathname){
  //   clearParams()
  //   return
  // }
  //
  // paramsObject[parameter] = stateObject
  // localStorage.setItem("location",window.location.pathname)
  //
  // url.searchParams.set('params', JSON.stringify(paramsObject))
  //
  // window.history.pushState({ path: url.href }, '', url.href)
};
export const ensureHttps = (url: string) => {
  if (!/^https?:\/\//i.test(url)) {
    return `https://${url}`;
  }
  return url;
};
export const setReportHashParams = (hash: string) => {
  return;
  const url = new URL(window.location.href);
  const clearParams = () => {
    url.searchParams.delete("hashParams");
    localStorage.removeItem("location");
    window.history.pushState({ path: url.href }, "", url.href);
  };
  if (window.location.pathname === "/dashboards") {
    clearParams();
    return;
  }

  const location = localStorage.getItem("location") || "";
  if (location?.length > 0 && location !== window.location.pathname) {
    clearParams();
    return;
  }
  localStorage.setItem("location", window.location.pathname);

  url.searchParams.set("hashParams", hash);

  window.history.pushState({ path: url.href }, "", url.href);
};

export const isEnumValue = (value: string, enumObject: any): boolean => {
  return Object.values(enumObject).includes(value);
};
export const getLineHeightForModalSettings = (
  selectedLength: number
): number => {
  const selectedHeight = selectedLength * 30;
  const defaultHeight = 350;
  if (isMobile()) {
    return defaultHeight;
  }
  return Math.max(selectedHeight, defaultHeight);
};
export const isMobile = () => window.innerWidth <= 600;

export const getCurrentAdPage = (currentLocation: string) => {
  if (isEnumValue(currentLocation, PagesPathnames)) {
    return currentLocation;
  } else {
    return PagesPathnames.autoPlacement;
  }
};

// Function checks if access for current school ended
// Returns true or false based on this
export const isAccessEnded = () => {
  const currentSchool = useTypedSelector((state) => state.currentSchool);

  try {
    return !currentSchool?.userAccess?.status;
  } catch (err) {
    console.log(err);
  }
};
export const getDefaultMetricsGroupings = (
    value: string,
    form: FormInstance<any>
) => {
  const displayValue = form.getFieldValue("display");

  const metricsAndGroupingsMapping = {
    reg: {
      metrics: displayValue === "number" ? defaultMetricsReg : defaultMetrics,
      groupings: displayValue === "number" ? defaultGroupingReg : defaultGroupings,
    },
    ad: {
      metrics: displayValue === "number" ? defaultMetricAd : defaultMetricsAd,
      groupings: displayValue === "number" ? defaultGroupingAd : defaultGroupingsAd,
    },
    web: {
      metrics:displayValue === "number" ? defaultMetricsWeb : defaultMetricsWebinar,
      groupings:displayValue === "number" ? defaultGroupingWeb : defaultGroupingsWebinar,
    },
    payment: {
      metrics: displayValue === "number" ? defaultMetricsOrderPayment : defaultMetricsPayment,
      groupings: displayValue === "number" ? defaultGroupingPayment : defaultGroupingsPayment,
    },
    visit: {
      metrics: defaultMetricsVisits,
      groupings: defaultGroupingsVisits,
    },
    order: {
      metrics: displayValue === "number" ? defaultMetricsOrderNumber : defaultMetricsOrder,
      groupings: displayValue === "number" ? defaultGroupingNumber : defaultGroupingsOrder,
    },
  };

  const metricsAndGroupings = metricsAndGroupingsMapping[value as keyof typeof metricsAndGroupingsMapping];

  const displaysWithDefaultValues = ["table", "chart", "number"];
  if (displaysWithDefaultValues.includes(displayValue)) {
    return {
      metrics: metricsAndGroupings?.metrics || [],
      groupings: metricsAndGroupings?.groupings || [],
    };
  }

  return {
    metrics: [],
    groupings: [],
  };
};


export const removeNbsp = (value: string) => {
  return value.replace(/&nbsp;/g, " ");
};
export const updateChildren = (element: any, key: string, fields: any): any => {
  if (element.children && element.children?.length > 0) {
    element.children = element.children.map((child: any) => {
      if (child.key === key) {
        child.children = fields;
      }
      return updateChildren(child, key, fields);
    });
  }

  return element;
};

export const convertToTreeFormat = (data: Record<string, string>) => {
  return Object.entries(data).map(([key, value]) => ({
    dataKey: key,
    name: value,
    label: value,
  }));
};
export const convertToFiltersFormat = (data: Record<string, string>) => {
  return Object.entries(data).map(([key, value]) => ({
    dataKey: key,
    name: value,
    full_name: value,
    label: value,
    id: key,
    type: "lazylist",
    isLeaf: true,
  }));
};
export const normalizeGroupingName = (
  groupings: ITableDataGeneric[],
  ending: string
) => {
  const regex = new RegExp(`${ending}$`);

  return groupings
    .map((col) => col.dataKey)
    .map((item) => {
      if (regex.test(item) && !item.includes("extra")) {
        return item;
      } else {
        return item + ending;
      }
    });
};
