export const SERVER_BASE_URL =
  process.env.REACT_APP_API_URL || "https://api.lk.scananalytics.ru";
// process.env.REACT_APP_API_URL || "https://api.lk.scananalytics.ru"

export const API_BASE_URL = `${SERVER_BASE_URL}/api`;

// auth routes
export const API_AUTH_SIGNUP = `${API_BASE_URL}/auth/create-user`;
export const API_AUTH_RESET = `${API_BASE_URL}/password-recovery`;
export const API_AUTH_RECOVERY = `${API_BASE_URL}/change-password`;
export const API_AUTH_LOGIN = `${API_BASE_URL}/auth/login`;
export const API_AUTH_LOGOUT = `${API_BASE_URL}/auth/logout`;
export const API_AUTH_REFRESH = `${API_BASE_URL}/auth/refresh-token`;

// school routes
export const API_SCHOOL_GET_DATA = `${API_BASE_URL}/get-user-info`;
export const API_GET_TOKEN = `${API_BASE_URL}/auth/get-user-token`;
export const API_SCHOOL_CHANGE = `${API_BASE_URL}/school-update`;
export const API_SCHOOL_GET_DETAILS = `${API_BASE_URL}/school-info`;
export const API_SCHOOL_ADD = `${API_BASE_URL}/school-add`;
export const API_SCHOOL_DELETE = `${API_BASE_URL}/school-delete`;
export const API_SCHOOL_SELECT = `${API_BASE_URL}/change-default-school`;
export const API_SCHOOL_FETCH_DOMAINS = `${API_BASE_URL}/domain-info`;
export const API_SCHOOL_FETCH_UTM = `${API_BASE_URL}/redirect-utm-status`;
export const API_SCHOOL_UPDATE_DOMAIN_STATUS = `${API_BASE_URL}/domain-status`;
export const API_SCHOOL_ADD_DOMAIN = `${API_BASE_URL}/domain-add`;
export const API_SCHOOL_DELETE_DOMAIN = `${API_BASE_URL}/domain-delete`;
export const API_SCHOOL_GET_VALIDATE_STATUS = `${API_BASE_URL}/email-validation-status`;
export const API_SCHOOL_SET_VALIDATE_STATUS = `${API_BASE_URL}/set-email-validation-status`;
export const API_SCHOOL_SET_UTM_STATUS = `${API_BASE_URL}/set-redirect-utm-status`;

// staff routes
export const API_STAFF_GET_DATA = `${API_BASE_URL}/employees-info`;
export const API_STAFF_GET_LISTS = `${API_BASE_URL}/employee-lists`;
export const API_STAFF_ADD_USER = `${API_BASE_URL}/employee-add`;
export const API_STAFF_GET_USER_INFO = `${API_BASE_URL}/employee-info`;
export const API_STAFF_SET_USER_INFO = `${API_BASE_URL}/employee-update`;
export const API_STAFF_DELETE_USER = `${API_BASE_URL}/employee-delete`;

// webinar routes
export const API_WEBINAR_GET_DATA = `${API_BASE_URL}/webinars-info`;
export const API_WEBINAR_UPDATE_TITLE = `${API_BASE_URL}/webinar-name-update`;

// ads spend routes
export const API_ADS_SPEND_GET_DATA = `${API_BASE_URL}/ads-spend-items`;
export const API_ADS_SPEND_COMP_LIST = `${API_BASE_URL}/expense/ads-companies-list`;
export const API_ADS_SPEND_GR_LIST = `${API_BASE_URL}/expense/ads-group-list`;
export const API_ADS_SPEND_AD_LIST = `${API_BASE_URL}/expense/ads-ad-list`;
export const API_ADS_SPEND_CREATE = `${API_BASE_URL}/expense/ads-add-rate`;
export const API_ADS_SPEND_GET_RATE_BY_ID = `${API_BASE_URL}/expense/find-one-ad`;
export const API_ADS_SPEND_GET_UPDATE_DATA = `${API_BASE_URL}/expense/ads-spend-info`;
export const API_ADS_SPEND_UPDATE = `${API_BASE_URL}/expense/ads-spend-update`;
export const API_ADS_SPEND_DELETE = `${API_BASE_URL}/expense/ads-spend-delete`;
export const API_ADS_SPEND_PROCESS = `${API_BASE_URL}/expense/reset-processed-ads `;

// ads
// export const API_ADS_GET_DATA = `${API_BASE_URL}/integration/items`
// export const API_ADS_UPDATE_STATUS = `${API_BASE_URL}/integration/set-status`
// export const API_ADS_UPDATE_NDS = `${API_BASE_URL}/integration/set-vat`
// export const API_ADS_UPDATE_CURRENCY = `${API_BASE_URL}/integration/set-currency`
// export const API_ADS_UPDATE_CONVERT = `${API_BASE_URL}/integration/set-percent`

// ads
export const API_ADS_GET_RK_LIST = `${API_BASE_URL}/expense/advert-cabinet-list`;
export const API_ADS_UPDATE_RK_NAME = `${API_BASE_URL}/expense/advert-cabinet-edit`;
export const API_ADS_ADD_MANUAL_RK = `${API_BASE_URL}/integration/add-account`;
export const API_ADS_ADD_RK_MANUAL = `${API_BASE_URL}/expense/advert-cabinet-add`;
export const API_ADS_GET_RK = `${API_BASE_URL}/expense/advert-cabinets`;
export const API_YANDEX_GET_METRICS = `${API_BASE_URL}/integration/counters`;
export const API_YANDEX_GET_TASKS_DATA = `${API_BASE_URL}/ym/task-items`;
export const API_YANDEX_REMOVE_TASK = `${API_BASE_URL}/ym/task-del`;
export const API_YANDEX_GET_TASKS = `${API_BASE_URL}/ym/task-types`;
export const API_YANDEX_GET_TASK = `${API_BASE_URL}/ym/task-item`;
export const API_YANDEX_ADD_GOAL = `${API_BASE_URL}/ym/goals-add`;
export const API_YANDEX_ADD_TASK = `${API_BASE_URL}/ym/task-add`;
export const API_YANDEX_GET_TASKS_OBJECTS = `${API_BASE_URL}/ym/task-objects`;
export const API_YANDEX_GET_GOALS = `${API_BASE_URL}/ym/goals`;
export const API_YANDEX_TASK_EDIT = `${API_BASE_URL}/ym/task-update`;

export const API_ADS_GET_DATA = `${API_BASE_URL}/integration/items`;
export const API_ADS_UPDATE_UTM_LABELS = `${API_BASE_URL}/integration/set-utms`;
export const API_ADS_ADD_RK_TOKEN = `${API_BASE_URL}/integration/token`;
export const API_ADS_GET_VK = `${API_BASE_URL}/integration/ad-accounts`;
export const API_ADS_ADD_RK = `${API_BASE_URL}/integration/add`;

export const API_ADS_UPDATE_STATUS = `${API_BASE_URL}/integration/set-status`;
export const API_ADS_UPDATE_UTM_STATUS = `${API_BASE_URL}/integration/set-utm-has-id`;
export const API_ADS_UPDATE_UTM = `${API_BASE_URL}/integration/set-utm-label`;
export const API_ADS_UPDATE_UTM_MASK = `${API_BASE_URL}/integration/set-utm-mask`;
export const API_ADS_UPDATE_AD_CABINET = `${API_BASE_URL}/integration/set-ad-cabinet`;
export const API_YANDEX_UPDATE_STATUS = `${API_BASE_URL}/integration/set-status-counter`;
export const API_YANDEX_REMOVE_COUNTER = `${API_BASE_URL}/integration/delete-counter`;
export const API_ADS_UPDATE_NDS = `${API_BASE_URL}/integration/set-vat`;
export const API_ADS_UPDATE_CURRENCY = `${API_BASE_URL}/integration/set-currency`;
export const API_ADS_UPDATE_CONVERT = `${API_BASE_URL}/integration/set-percent`;

// school files
export const API_SCHOOL_FILE_UPLOAD = `${API_BASE_URL}/export-csv-upload`;
export const API_SCHOOL_INFO = `${API_BASE_URL}/export-csv-info`;

// reports
export const API_CRM_TARIFF_ADD = `${API_BASE_URL}/crm/tariff-add`;
export const API_CRM_SCHOOL_UPDATE = `${API_BASE_URL}/crm/school-update`;
export const API_CRM_SCHOOL_INFO = `${API_BASE_URL}/crm/school-info`;
export const API_PAYMENT_UPDATE = `${API_BASE_URL}/crm/payment-update`;
export const API_PAYMENT_ADD = `${API_BASE_URL}/crm/payment-add`;
export const API_PAYMENT_INFO = `${API_BASE_URL}/crm/payment-info`;
export const API_GET_FILTER_LIST = `${API_BASE_URL}/filter/list-values`;
export const API_GET_FILTER_LIST_EXTRA = `${API_BASE_URL}/extra-fields/get-values`;
export const API_ORDER_ADD = `${API_BASE_URL}/crm/order-add`;
export const API_ORDER_INFO = `${API_BASE_URL}/crm/order-info`;
export const API_ORDER_UPDATE = `${API_BASE_URL}/crm/order-update`;
export const API_UN_REPORT_GET_REPORT_DATA = `${API_BASE_URL}/operation/load-report`;
export const API_REFERAL_CONNECT = `${API_BASE_URL}/crm/referral-bindings`;
export const API_REPORTS_GET_CUSTOM_METRICS = `${API_BASE_URL}/custom-metrics/items`;
export const API_REPORTS_CREATE_CUSTOM_METRIC = `${API_BASE_URL}/custom-metrics/add`;
export const API_REPORTS_REMOVE_CUSTOM_METRIC = `${API_BASE_URL}/custom-metrics/del`;
export const API_REPORTS_GET_CUSTOM_METRIC = `${API_BASE_URL}/custom-metrics/item`;
export const API_REPORTS_EDIT_CUSTOM_METRIC = `${API_BASE_URL}/custom-metrics/upd`;
export const API_REFERAL_TABLE_REFERALS = `${API_BASE_URL}/crm/get-referrals-info`;
export const API_REFERAL_TABLE_PAYMENTS = `${API_BASE_URL}/crm/get-partner-payments-info`;
export const API_REFERAL_TABLE_ACCURALS = `${API_BASE_URL}/crm/get-calculation-info`;
export const API_REFERAL_TABLE_PARTNERS = `${API_BASE_URL}/crm/get-partners-info`;
export const API_TABLE_ALL_DATA = `${API_BASE_URL}/latest-data-received`;
export const API_ORDERS_STATUS_UPDATE = `${API_BASE_URL}/order-update-status`;
export const API_REGS_PROCESSING = `${API_BASE_URL}/expense/reset-processed-app`;
export const API_REGS_MASS_REMOVING = `${API_BASE_URL}/expense/delete-ads`;
export const API_REFERAL_DELETE_ACCURAL = `${API_BASE_URL}/crm/delete-calculation`;
export const API_REFERAL_TABLE_UPDATE = `${API_BASE_URL}/crm/update-referrals`;
export const API_REFERAL_TABLE_UPDATE_COMMISSION = `${API_BASE_URL}/crm/commission-charge`;
export const API_REFERAL_TABLE_UPDATE_PARTNER = `${API_BASE_URL}/crm/update-partners`;
export const API_REFERAL_GET_TABLE_PARTNER = `${API_BASE_URL}/crm/info-update-partners`;
export const API_REFERAL_GET_INFO = `${API_BASE_URL}/crm/update-referrals-info`;
export const API_REFERAL_DELETE = `${API_BASE_URL}/crm/delete-referral`;
export const API_REFERAL_TABLE_UPDATE_PAYMENT = `${API_BASE_URL}/crm/update-partner-payments`;
export const API_REFERAL_TABLE_GET_PAYMENT = `${API_BASE_URL}/crm/update-partner-payments-info`;
export const API_REFERAL_TABLE_DELETE_PAYMENT = `${API_BASE_URL}/crm/delete-partner-payments`;
export const API_REFERAL_TABLE_UPDATE_ACCURAL = `${API_BASE_URL}/crm/update-calculation`;
export const API_REFERAL_ADD_PAYMENT = `${API_BASE_URL}/crm/add-partner-payments`;
export const API_UPDATE_USER_INFO = `${API_BASE_URL}/crm/user-update`;
export const API_GET_USER_INFO = `${API_BASE_URL}/crm/user-info`;
export const API_UPLOAD_EXCEL_SOURCES = `${API_BASE_URL}/export-csv-upload`;
export const API_UN_SEGMENT_GET_SEGMENT_DATA = `${API_BASE_URL}/operation/load-segment`;
export const API_UN_REPORT_FETCH_FILTER = `${API_BASE_URL}/filter/list-values`;
export const API_REPORT_GET_EXTRA_FIELDS = `${API_BASE_URL}/extra-fields/get-fields`;

export const API_UN_REPORT_TABLE = `${API_BASE_URL}/filter/table`;
export const API_UN_REPORT_CHART = `${API_BASE_URL}/filter/chart`;

export const API_REPORT_TABLE = `${API_BASE_URL}/filter/table`;
export const API_WEBINAR_REPORT_CHART = `${API_BASE_URL}/filter/chart`;
export const API_TG_DELETE_TASK = `${API_BASE_URL}/reports-to-telegram/delete-task`;
export const API_TG_UPDATE_TASK = `${API_BASE_URL}/reports-to-telegram/update-task`;
export const API_TG_CHECK_REPORT = `${API_BASE_URL}/reports-to-telegram/check-for-report`;
export const API_TG_GET_TASK = `${API_BASE_URL}/reports-to-telegram/task-for-report`;
export const API_TG_TASKS_USER = `${API_BASE_URL}/reports-to-telegram/task-for-user`;
export const API_TG_AVAILABLE_USERS = `${API_BASE_URL}/reports-to-telegram/available-users`;
export const API_TG_ADD_TASK = `${API_BASE_URL}/reports-to-telegram/set-task`;
export const API_UN_REPORT_GET_REPORTS = `${API_BASE_URL}/operation/get-reports`;
export const API_UN_REPORT_SET_REPORT = `${API_BASE_URL}/operation/set-report`;
export const API_UN_REPORT_UPDATE_REPORT = `${API_BASE_URL}/operation/update-report`;
export const API_UN_REPORT_DELETE_REPORT = `${API_BASE_URL}/operation/delete-report`;
// dashboards methods
export const API_DASHBOARDS_GET_SAVED = `${API_BASE_URL}/operation/get-dashboards`;
export const API_DASHBOARDS_GET_BY_ID = `${API_BASE_URL}/operation/dashboard-data`;

export const API_UN_REPORT_GET_SEGMENTS = `${API_BASE_URL}/operation/get-segments`;
export const API_UN_REPORT_SET_SEGMENTS = `${API_BASE_URL}/operation/set-segment`;
export const API_UN_REPORT_UPDATE_SEGMENTS = `${API_BASE_URL}/operation/update-segment`;
export const API_UN_REPORT_DELETE_SEGMENTS = `${API_BASE_URL}/operation/delete-segment`;
export const API_UN_REPORT_GET_FUNNELS = `${API_BASE_URL}/operation/get-funnels`;
export const API_UN_REPORT_SET_FUNNELS = `${API_BASE_URL}/operation/set-funnel`;
export const API_UN_REPORT_UPDATE_FUNNELS = `${API_BASE_URL}/operation/update-funnel`;
export const API_UN_REPORT_DELETE_FUNNELS = `${API_BASE_URL}/operation/delete-funnel`;
export const API_UN_REPORT_EXEL = `${API_BASE_URL}/operation/excel`;

// exports
export const API_GET_EXPORTS = `${API_BASE_URL}/operation/list-export`;
export const API_REQ_EXPORT_TABLE = `${API_BASE_URL}/operation/set-export`;
export const API_GET_EXPORT = `${API_BASE_URL}/operation/download-export`;
export const API_CRM_GET_REQUEST = `${API_BASE_URL}/operation/download-sql`;

// bill
export const API_GET_EXPORT_SCORE = `${API_BASE_URL}/billing/pdfreq`;
export const API_GET_EXPORT_ACT = `${API_BASE_URL}/billing/pdfcomp`;
export const API_GET_REQUISITES_LIST = `${API_BASE_URL}/billing/modreq`;

export const API_DELETE_REQUISITE = `${API_BASE_URL}/billing/deletereq`;
export const API_GET_REQUISITE = `${API_BASE_URL}/billing/getreq`;
export const API_GET_REQUISITES = `${API_BASE_URL}/billing/listreq`;
export const API_SAVE_REQUISITES = `${API_BASE_URL}/billing/savereq`;
export const API_GET_BALANCE_DATA = `${API_BASE_URL}/billing/balance`;
export const API_GET_BILL_DATA = `${API_BASE_URL}/billing/score`;
export const API_GET_TRANSACTIONS_DATA = `${API_BASE_URL}/billing/detailing`;

// profile
export const API_UPDATE_USER_CONTACTS = `${API_BASE_URL}/update-user-info`;
export const API_UPDATE_USER_PASSWORD = `${API_BASE_URL}/auth/passupdate`;
export const API_GET_TG_LINK = `${API_BASE_URL}/get-telegram-token`;

// log hits
export const API_SEND_HIT = `${API_BASE_URL}/visit/hit`;

// partnerPage
export const API_PARTNER_GET_REFERALS = `${API_BASE_URL}/partner/referral-list`;
export const API_PARTNER_GET_ACCRUALS = `${API_BASE_URL}/partner/list-of-accruals`;
export const API_PARTNER_GET_PAYOUTS = `${API_BASE_URL}/partner/payout-list`;
export const API_PARTNER_GET_STATISTICS = `${API_BASE_URL}/partner/statistics`;
