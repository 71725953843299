import React, { useState, useEffect, FC, useMemo, useRef } from "react";

import {
  BgColorsOutlined,
  CopyOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Empty, Menu, message, Space, Tooltip } from "antd";
import "react-base-table/styles.css";
import _ from "lodash";
import moment from "moment";
import BaseTable from "react-base-table";
import { v4 as uuidv4 } from "uuid";

import ExpandIcon from "../un-table/components/ExpandIcon";
import GroupHeader from "./components/GroupHeader";
import HeaderCell from "./components/HeaderCell";
import GroupCell from "../un-table/components/GroupCell";
import {
  checkConvertDataType,
  checkMoneyDataType,
  checkNativeDataType,
  formatCellData,
  formatConvertData,
  formatMoneyData,
  finishRows,
  checkAverageDataType,
  checkSummaryDataType,
  checkPercentDataType,
} from "../../../../../helpers/utils/reports";
import { showErrorMessage } from "../../../../../helpers/utils/ui";
import { unApi } from "../../../api/endpoints/reports/unApi";
import { useActions } from "../../../hooks/useActions";
import { useForceUpdate } from "../../../hooks/useForceUpdate";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import Loading from "../../loader/Loader";
import GroupingsOrder from "../GroupingsOrder";
import MetricsOrder from "../MetricsOrder";
import { Properties } from "csstype";
import {
  percentFormatGroup,
  summaryFormatGroup,
} from "../../../constants/groups";
import {
  findObjectById,
  getSummaryOfGroupings,
  normalizeGroupingName,
} from "../../../../../helpers/utils/functions";
import { findTopParentObjectName } from "../../../constants/reports";
import { useDidComponentUpdate } from "../../../hooks/useDidComponentUpdate";
import {
  getCustomMetricRow,
  processItems,
} from "../../../../../helpers/utils/tableHelpers";

const COL_GROUP_MINWIDTH = 260;
const COL_WIDTH = 120;
const COL_MIN_WIDTH = 120;
const COL_MAX_WIDTH = 900;
const HEADER_HEIGHT = 110;

const UniversalTable: FC<any> = ({ hidePersonalDataGroups }) => {
  const forceUpdate = useForceUpdate();
  const [isFilling, setIsFilling] = useState(false);
  const { selectedSchool } = useTypedSelector((state) => state.currentSchool);
  const period = useTypedSelector((state) => state.period);
  const { attributes } = useTypedSelector((state) => state.attributes);
  const { filters } = useTypedSelector((state) => state.filter);
  const downloadedRowKeys = useRef<string[]>([]);
  const [expandedAll, setExpandedAll] = useState([]);
  const {
    isCompare,
    isTableLoading,
    isChartLoading,
    isTableFetching,
    isChartFetching,
    currency,
    isAllExpandedLoading,
    isTableCompareFetching,
  } = useTypedSelector((state) => state.meta);
  const { chartType } = useTypedSelector((state) => state.chart);
  const {
    groupings,
    metrics,
    tableData,
    percentages,
    filtersTable,
    sortTable,
    groupsChecked,
    metricsChecked,
    totalChecked,
  } = useTypedSelector((state) => state.table);
  const { compareFilters, comparePeriod } = useTypedSelector(
    (state) => state.compare
  );

  const {
    setMetaTableLoading,
    setGroupsLimit,
    setMetricsLimit,
    setTableData,
    setTableChildrenData,
    setMetaCompareTableFetching,
    setMetaTableFetching,
    setTotalChecked,
    setMetricsChecked,
    setSortTable,
    setFiltersTable,
    setTotalChosenMetrics,
    setTotalChosenStatus,
    getCreatedMetrics,
    setIsAllExpandedLoading,
    clearTotalChecked,
  } = useActions();

  const [dimensionsTable, setDimensionsTable] = useState({ height: 0 });
  const [data, setData] = useState([]);
  const [frozensColumns, setFrozensColumns] = useState<any>({});
  const [expandedRowKeys, setExpandedRowKeys] = useState<any>([]);
  const [loadRowKeys, setLoadRowKeys] = useState<any>([]);
  const [tableWidth, setTableWidth] = useState(1200);
  const [columnsTable, setColumnsTable] = useState<any>([]);
  const [adsGroupsSelected, setAdsGroupsSelected] = useState(false);
  const [adsGroupsAvailable, setAdsGroupsAvailable] = useState(false);
  const [didUpdate, handleUpdate] = useDidComponentUpdate();
  const tableDiv = document.getElementById("universal_table");

  const handleAllRowsCheck = (pathStrings: string[]) => {
    /*
        re-download all checked
          if(pathStrings.length === 0){
            setLoadRowKeys([])
            downloadedRowKeys.current = []
        }
         */

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    setExpandedAll((prev: string[]) =>
      pathStrings.length === 0 ? [] : [...new Set([...prev, ...pathStrings])]
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    setExpandedRowKeys((prev: string[]) =>
      pathStrings.length === 0 ? [] : [...new Set([...prev, ...pathStrings])]
    );
  };
  const handleGroupsChanged = (
    _groupsChecked: any,
    _metricsChecked: any,
    _totalChecked: any,
    _chartType: string
  ) => {
    if (_chartType === "bubble") {
      setMetricsLimit(false);
      setGroupsLimit(false);
      return;
    }

    if (_groupsChecked.length > 0) {
      if (_totalChecked.length > 0) {
        setMetricsLimit(true);
      } else {
        if (_groupsChecked.length > 1) {
          setMetricsLimit(true);
        } else {
          setMetricsLimit(true);
        }
      }
    } else {
      setMetricsLimit(false);
    }

    if (_metricsChecked.length > 1) {
      setGroupsLimit(true);
    } else {
      setGroupsLimit(false);
    }
  };

  useEffect(() => {
    handleGroupsChanged(groupsChecked, metricsChecked, totalChecked, chartType);
  }, [groupsChecked, metricsChecked, totalChecked, chartType]);

  useEffect(() => {
    if (tableDiv) {
      const calcTableWidth = () => {
        const width = tableDiv.offsetWidth;
        setTableWidth(width <= 1000 ? 1000 : width);
      };

      new ResizeObserver(calcTableWidth).observe(tableDiv);
      calcTableWidth();
    }
  }, [tableDiv]);

  const loadCompareRows = (loadCurrent?: any) => {
    setMetaTableLoading({ value: true });

    const { compareFilters1, compareFilters2 } = compareFilters;
    const { comparePeriod1, comparePeriod2 } = comparePeriod;
    const { attribution, type_applications } = attributes;

    const format = "YYYY-MM-DD";
    const groupings_names = normalizeGroupingName(groupings, "_2");
    const metrics_names = metrics
      .map((col) => col.dataKey)
      .map((item) =>
        /_2$/.test(item) || item.startsWith("_") ? item : item + "_2"
      );
    const expanded_ids = loadCurrent ? loadCurrent.clientData.path : [];

    if (expanded_ids.length === groupings_names.length) {
      setMetaTableLoading({ value: false });
      localStorage.setItem(
        "lastLoadDateTime",
        JSON.stringify({ time: new Date().toString(), completed: true })
      );
      return;
    }

    const _comparePeriod1 = {
      start: moment(comparePeriod1.start).format(format),
      end: moment(comparePeriod1.end).format(format),
    };
    const _comparePeriod2 = {
      start: moment(comparePeriod2.start).format(format),
      end: moment(comparePeriod2.end).format(format),
    };

    const data = {
      comparePeriod1: _comparePeriod1,
      comparePeriod2: _comparePeriod2,
      compareFilters1: compareFilters1,
      compareFilters2: compareFilters2,
      attribution: {
        attribution: attribution,
        type_applications: type_applications,
      },
      groupings_names,
      metrics_names,
      expanded_ids,
    };
    const prevRequestState = window.localStorage.getItem("prevRequestState");
    let parsedPrevState = {};
    if (prevRequestState) {
      parsedPrevState = JSON.parse(prevRequestState);
    }

    if (_.isEqual(data, parsedPrevState)) {
      setMetaTableLoading({ value: false });
      return;
    }
    window.localStorage.setItem("prevRequestState", JSON.stringify(data));

    unApi
      .getUniversalReportTableData(data, location.pathname)
      .then((response) => {
        const rowsWithIdAsString = response.data.rows.map((row: any) => {
          if (row.id) {
            return { ...row, id: row.id.toString() };
          }
          return row;
        });

        const data = { ...response.data, rows: rowsWithIdAsString };
        if (data.success) {
          if (loadCurrent) {
            setTableChildrenData({ loadCurrent, data, groupings });
            setExpandedRowKeys((prev: any) => [...prev, loadCurrent.pathStr2]);
            setLoadRowKeys((prev: any) =>
              prev.filter((item: any) => item !== loadCurrent.pathStr2)
            );
          } else {
            setTableData({ ...data, groupings });
            setExpandedRowKeys([]);
            setLoadRowKeys([]);
          }
        } else {
          showErrorMessage("Ошибка при получении данных");
          if (response.status === 504) {
            showErrorMessage(
              "Запрос выполняется слишком долго, из-за большого количества данных. Можно уменьшить период отчёта, удалить не нужные метрики или фильтры."
            );
          }

          console.log("Ошибка");
        }
        setMetaTableLoading({ value: false });
      })
      .catch((error) => {
        console.log("error = ", error);
        if (error?.response?.status !== 401) {
            showErrorMessage("Ошибка при получении данных");
        }
        setMetaTableLoading({ value: false });
      });
  };

  const loadRows = (loadCurrent?: any) => {
    localStorage.setItem(
      "lastLoadDateTime",
      JSON.stringify({ time: new Date().toString(), completed: false })
    );
    let __attribution = attributes?.attribution;
    let __type_app = attributes?.type_applications;
    let __period = period;

    setMetaTableLoading({ value: true });

    const format = "YYYY-MM-DD";
    const _period = {
      start: moment(__period.start).format(format),
      end: moment(__period.end).format(format),
    };
    const groupings_names = normalizeGroupingName(groupings, "_2");
    const metrics_names = metrics
      .map((col) => col.dataKey)
      .map((item) =>
        /_2$/.test(item) || item.startsWith("_") ? item : item + "_2"
      );
    const expanded_ids = loadCurrent ? loadCurrent.clientData.path : [];

    if (expanded_ids.length === groupings_names.length) {
      setMetaTableLoading({ value: false });
      localStorage.setItem(
        "lastLoadDateTime",
        JSON.stringify({ time: new Date().toString(), completed: true })
      );
      return;
    }
    let data = {
      period: _period,
      filters: filters,
      attribution: {
        attribution:
          typeof __attribution === "string" ? [__attribution] : __attribution,
        type_applications:
          typeof __type_app === "string" ? [__type_app] : __type_app,
      },
      groupings_names,
      metrics_names,
      expanded_ids,
    };

    window.localStorage.setItem("prevRequestState", JSON.stringify(data));
    console.log("=>> REQUEST =>>\n", data, "\n=>> REQUEST =>>");
    unApi
      .getUniversalReportTableData(data, location.pathname)
      .then((response) => {
        const rowsWithIdAsString = response.data.rows.map((row: any) => {
          if (row.id) {
            return { ...row, id: row.id.toString() };
          }
          return row;
        });

        const data = { ...response.data, rows: rowsWithIdAsString };

        console.log("<<= RESPONSE <<=\n", data, "\n<<= RESPONSE <<=");
        if (data.success) {
          if (loadCurrent) {
            setTableChildrenData({ loadCurrent, data, groupings });
            setExpandedRowKeys((prev: any) => [...prev, loadCurrent.pathStr2]);
            setLoadRowKeys((prev: any) =>
              prev.filter((item: any) => item !== loadCurrent.pathStr2)
            );
          } else {
            setTableData({ ...data, groupings });
            setExpandedRowKeys([]);
            setLoadRowKeys([]);
          }
        } else {
          showErrorMessage("Ошибка при получении данных");
          if (response.status === 504) {
            showErrorMessage(
              "Запрос выполняется слишком долго, из-за большого количества данных. \n Можно уменьшить период отчёта, удалить ненужные метрики или фильтры."
            );
          }

          console.log("Ошибка");
          setMetaTableFetching({ value: false });
        }
        if (Object.keys(sortTable).length === 0) {
          setSortTable({ random: "Sd" });
          setSortTable({});
        }
        setMetaTableLoading({ value: false });
        // setCompareLoading((prevState) => ([ ...prevState.filter((val) => val.time !== time), {time: time, loading: false}]))
      })
      .catch((error) => {
        console.log("error = ", error);
        if (error?.response?.status !== 401) {
            showErrorMessage("Ошибка при получении данных");
        }
        if (error.response) {
          if (error.response.status === 504) {
            showErrorMessage(
              "Запрос выполняется слишком долго, из-за большого количества данных. \n Можно уменьшить период отчёта, удалить ненужные метрики или фильтры."
            );
          }
        }

        setData([]);
        setMetaTableLoading({ value: false });
      })
      .finally(() => {
        localStorage.setItem(
          "lastLoadDateTime",
          JSON.stringify({ time: new Date().toString(), completed: true })
        );
      });
  };

  useEffect(() => {
    window.localStorage.setItem("prevRequestState", JSON.stringify({}));
    if (selectedSchool?.id) {
      getCreatedMetrics(selectedSchool?.id);
    }
  }, []);
  useEffect(() => {
    if (groupsChecked.length === 0) {
      let initialChosenMetrics: any = {};
      metrics.forEach((el) => {
        initialChosenMetrics[el.dataKey] = 0;
      });
      setTotalChosenMetrics(initialChosenMetrics);
      setTotalChosenStatus([]);
    } else {
      const result = getSummaryOfGroupings(groupsChecked, data);
      result ? setTotalChosenMetrics(result) : null;
    }
  }, [groupsChecked, data.length]);
  useEffect(() => {
    if (isCompare) {
      if (!isTableCompareFetching) return;
      loadCompareRows();
      setMetaCompareTableFetching({ value: false });
    } else {
      if (!isTableFetching) return;
      if (!didUpdate) {
        handleUpdate();
        setMetaTableFetching({ value: false });
        setMetaTableLoading({ value: false });
        forceUpdate();
        setTimeout(() => {
          setMetaTableFetching({ value: true });
          setMetaTableLoading({ value: true });
        }, 300);
        return;
      }
      loadRows();
      setMetaTableFetching({ value: false });
    }

    setMetaTableFetching({ value: false });
    setMetaCompareTableFetching({ value: false });
  }, [
    isTableFetching,
    isTableCompareFetching,
    metrics,
    groupings,
    forceUpdate,
  ]);

  useEffect(() => {
    if (metrics.length === 0) return;
    const url = new URL(window.location.href);
    const currentParams = url.searchParams.get("params");
    const paramsObject = currentParams ? JSON.parse(currentParams) : {};
    const isTotalChecked = paramsObject?.totalChecked?.length > 0 || true; // Поправить в будущем убрать true #ПРАВКА
    isTotalChecked ? setTotalChecked() : clearTotalChecked();
    setMetricsChecked({
      data: [{ name: metrics[0].dataKey, label: metrics[0].name }],
    });
    const intervalId = setInterval(() => {
      const lastLoadDateTimeStr =
        window.localStorage.getItem("lastLoadDateTime");
      if (lastLoadDateTimeStr && isAllExpandedLoading) {
        const { time, completed } = JSON.parse(lastLoadDateTimeStr);
        const lastLoadDateTime = new Date(time);
        const now = new Date();
        const diffInSeconds = (+now - +lastLoadDateTime) / 1000;
        const element = document.querySelector(".BaseTable__body");
        if (element) {
          const percent = element.scrollHeight * 0.05;
          const scrollPositionCondition =
            Math.ceil(element.scrollTop + element.clientHeight) <
            element.scrollHeight;
          if (scrollPositionCondition && completed) {
            element.scrollBy({ top: percent, behavior: "smooth" });
          } else {
            if (diffInSeconds >= 1.5 && completed) {
              window.localStorage.removeItem("lastLoadDateTime");
              setIsAllExpandedLoading({ value: false });
            }
          }
        } else {
          if (diffInSeconds >= 1.5 && completed) {
            window.localStorage.removeItem("lastLoadDateTime");
            setIsAllExpandedLoading({ value: false });
          }
        }
      }
    }, 500);
    return () => clearInterval(intervalId);
  }, [isAllExpandedLoading]);

  useEffect(() => {
    const docEl = document.documentElement;
    setDimensionsTable({
      height: docEl.clientHeight - 150,
    });
  }, [tableWidth]);

  const isGroupsChanged = useRef(false);

  const widthGroupCol = useMemo(() => {
    let width = tableWidth - 20 - metrics.length * COL_WIDTH;
    return width > COL_GROUP_MINWIDTH ? width : COL_GROUP_MINWIDTH;
  }, [tableWidth, metrics?.length]);

  const [columnWidth, setColumnWidth] = useState<number>(widthGroupCol);
  const handleCellWidthChange = (width: number) => {
    if (isGroupsChanged.current) {
      isGroupsChanged.current = false;
      return;
    }
    setColumnWidth((prev) =>
      width > COL_GROUP_MINWIDTH && width > prev
        ? width
        : prev > COL_GROUP_MINWIDTH
        ? prev
        : COL_GROUP_MINWIDTH
    );
  };
  useEffect(() => {
    let width = tableWidth - 20 - metrics.length * COL_WIDTH;
    setColumnWidth(width > COL_GROUP_MINWIDTH ? width : COL_GROUP_MINWIDTH);
    isGroupsChanged.current = true;
  }, [groupings]);

  const [columnsWidth, setColumnsWidth] = useState<any>({});
  useEffect(() => {
    if (isCompare) {
      const calculateMaxWidth = () => {
        const result: any = {};
        const elements = document.getElementsByClassName("column-span");

        for (let i = 0; i < elements.length; i++) {
          result[elements[i].classList[1]] = Math.max(
            elements[i].getBoundingClientRect().width,
            !result[elements[i].classList[1]]
              ? 0
              : result[elements[i].classList[1]]
          );
        }
        for (const key in result) {
          result[key] = result[key] + 40;
        }
        return result;
      };
      const width = calculateMaxWidth();
      setColumnsWidth(width);
    }
  }, [isChartFetching, isTableLoading]);

  useEffect(() => {
    let _data = [];
    if (isFilling) {
      _data = [
        {
          title: "Группа",
          dataKey: "group",
          key: "group",
          width: columnWidth,
          minWidth: COL_GROUP_MINWIDTH,
          maxWidth: COL_MAX_WIDTH,
          frozen: "left",
          resizable: true,
          groupings,
          sortTable,
          isFilling,
          loadRowKeys,
          columnWidth,
          headerRenderer: () => (
            <GroupHeader
              sort={sortTable}
              dataKey={"group"}
              handleExpandAll={handleAllRowsCheck}
            />
          ),
          cellRenderer: ({ rowData }: any) => (
            <GroupCell
              rowData={rowData}
              expandedRowKeys={expandedRowKeys}
              onCellWidthChange={handleCellWidthChange}
            />
          ),
        },
        ...metrics.map((item: any) => {
          let { name, description, dataKey } = item;
          return {
            title: name,
            description,
            dataKey,
            item,
            background: item?.background,
            plan: item?.plan,
            base_metric_name: item?.base_metric_name,
            metric_formula: item?.metric_formula,
            key: dataKey,
            width: COL_WIDTH,
            minWidth: COL_MIN_WIDTH,
            maxWidth: COL_MAX_WIDTH,
            resizable: true,
            filtersTable,
            sortTable,
            frozen: frozensColumns[dataKey] || false,
            headerRenderer: ({ column }: any) => {
              return (
                <HeaderCell
                  column={column}
                  filters={filtersTable}
                  sort={sortTable}
                  setFrozens={setFrozensColumns}
                />
              );
            },
            cellRenderer: (renderItem: any) => {
              const customStyle: Properties = {
                width: "100%",
                backgroundImage: "unset",
                textAlign: "right",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                height: "38px",
                paddingRight: "4px",
              };
              const result: {
                [key: string]: {
                  max: number;
                  maxPositive: number;
                  maxNegative: number;
                };
              } = {};

              if (checkAverageDataType(dataKey)) {
                data
                  .filter(
                    (item: { pathStr: string }) => item.pathStr !== "total"
                  )
                  .forEach((item: { [key: string]: any }) => {
                    Object.entries(item).forEach(([key, value]) => {
                      const numericValue = Number(value);
                      if (result[key]) {
                        result[key].max = Math.max(
                          result[key].max,
                          numericValue
                        );
                        result[key].maxPositive = Math.max(
                          result[key].maxPositive,
                          numericValue
                        );
                        result[key].maxNegative = Math.min(
                          result[key].maxNegative,
                          numericValue
                        );
                      } else {
                        result[key] = {
                          maxNegative: numericValue >= 0 ? 0 : numericValue,
                          maxPositive: numericValue >= 0 ? numericValue : 0,
                          max: numericValue,
                        };
                      }
                    });
                  });

                let percent = 0;
                if (result[dataKey]) {
                  const maxPositive = Math.max(
                    result[dataKey].maxPositive,
                    Math.abs(result[dataKey].maxNegative)
                  );
                  percent =
                    (Math.abs(Number(renderItem.cellData)) / maxPositive) * 100;
                  const checkSign = (value: number) =>
                    value > 0 ? "#9BDD9B" : "#ED9B9B";
                  if (
                    renderItem.rowData.group !== "Итого и среднее" ||
                    renderItem.rowData.group !== "Итого и среднее"
                  ) {
                    customStyle.backgroundImage = `linear-gradient(to left, ${checkSign(
                      renderItem.cellData
                    )} ${
                      Math.abs(Number(renderItem.cellData)) !== 0 && percent < 5
                        ? 5
                        : percent
                    }%, transparent ${
                      Math.abs(Number(renderItem.cellData)) !== 0 && percent < 5
                        ? 5
                        : percent
                    }%)`;
                  }
                }

                return (
                  <span style={customStyle}>
                    {formatCellData(renderItem.cellData, dataKey)}
                  </span>
                );
              }

              if (checkSummaryDataType(dataKey)) {
                data.forEach((item: { pathStr: string }) => {
                  if (item.pathStr !== "total") {
                    Object.entries(item).forEach(([key, value]) => {
                      if (summaryFormatGroup.includes(key.replace(/_2$/, ""))) {
                        const numericValue = Number(value);
                        if (result[key]) {
                          result[key].max += numericValue;
                          if (numericValue > 0) {
                            result[key].maxPositive += numericValue;
                          } else {
                            result[key].maxNegative += numericValue;
                          }
                        } else {
                          result[key] = {
                            maxNegative: numericValue >= 0 ? 0 : numericValue,
                            maxPositive: numericValue >= 0 ? numericValue : 0,
                            max: numericValue,
                          };
                        }
                      }
                    });
                  }
                });

                let percent = 0;
                if (Object.keys(result).includes(dataKey)) {
                  const maxPositive =
                    result[dataKey].maxPositive >=
                    Math.abs(result[dataKey].maxNegative)
                      ? result[dataKey].maxPositive
                      : Math.abs(result[dataKey].maxNegative);
                  percent =
                    (Math.abs(Number(renderItem.cellData)) / maxPositive) * 100;
                  const checkSign = (value: number) =>
                    value > 0 ? "#9BDD9B" : "#ED9B9B";
                  if (
                    renderItem.rowData.group !== "Итого и среднее" ||
                    renderItem.rowData.group !== "Итого и среднее"
                  ) {
                    customStyle.backgroundImage = `linear-gradient(to left, ${checkSign(
                      renderItem.cellData
                    )} ${
                      Math.abs(Number(renderItem.cellData)) !== 0 && percent < 5
                        ? 5
                        : percent
                    }%, transparent ${
                      Math.abs(Number(renderItem.cellData)) !== 0 && percent < 5
                        ? 5
                        : percent
                    }%)`;
                  }
                  const money = (
                    <span style={customStyle}>
                      {formatMoneyData(renderItem.cellData, currency, dataKey)}
                    </span>
                  );
                  const notMoney = (
                    <span style={customStyle}>
                      {formatCellData(renderItem.cellData, dataKey)}
                    </span>
                  );
                  return dataKey.includes("count") ? notMoney : money;
                }
              }

              if (checkPercentDataType(dataKey)) {
                processItems(data, result, percentFormatGroup);

                let percent = 0;
                if (result[dataKey]) {
                  const maxPositive = Math.max(
                    result[dataKey].maxPositive,
                    Math.abs(result[dataKey].maxNegative)
                  );
                  percent =
                    (Math.abs(Number(renderItem.cellData)) / maxPositive) * 100;
                  const checkSign = (value: number) =>
                    value > 0 ? "#9BDD9B" : "#ED9B9B";
                  if (
                    renderItem.rowData.group !== "Итого и среднее" ||
                    renderItem.rowData.group !== "Итого и среднее"
                  ) {
                    customStyle.backgroundImage = `linear-gradient(to left, ${checkSign(
                      renderItem.cellData
                    )} ${
                      Math.abs(Number(renderItem.cellData)) !== 0 && percent < 5
                        ? 5
                        : percent
                    }%, transparent ${
                      Math.abs(Number(renderItem.cellData)) !== 0 && percent < 5
                        ? 5
                        : percent
                    }%)`;
                  }
                  return (
                    <span style={customStyle}>
                      {formatConvertData(renderItem.cellData, dataKey)}
                    </span>
                  );
                } else {
                  return (
                    <span
                      style={{
                        textAlign: "right",
                        width: "100%",
                        paddingRight: "4px",
                      }}
                    >
                      {formatConvertData(renderItem.cellData, dataKey)}
                    </span>
                  );
                }
              }
              return getCustomMetricRow(
                dataKey,
                renderItem,
                item,
                currency,
                data
              );
            },
          };
        }),
      ];
    } else {
      _data = [
        {
          title: "Группа",
          dataKey: "group",
          key: "group",
          width: columnWidth,
          minWidth: COL_GROUP_MINWIDTH,
          maxWidth: COL_MAX_WIDTH,
          frozen: "left",
          resizable: true,
          groupings,
          sortTable,
          loadRowKeys,
          columnWidth,
          headerRenderer: () => (
            <GroupHeader
              sort={sortTable}
              dataKey={"group"}
              handleExpandAll={handleAllRowsCheck}
            />
          ),
          cellRenderer: ({ rowData }: any) => (
            <GroupCell
              rowData={rowData}
              expandedRowKeys={expandedRowKeys}
              onCellWidthChange={handleCellWidthChange}
            />
          ),
        },
        ...metrics.map((item: any) => {
          const { name, description, dataKey } = item;
          return {
            title: name,
            description,
            dataKey,
            background: item?.background,
            item,
            plan: item?.plan,
            base_metric_name: item?.base_metric_name,
            metric_formula: item?.metric_formula,
            key: dataKey,
            width:
              columnsWidth[dataKey] > COL_WIDTH
                ? columnsWidth[dataKey]
                : COL_WIDTH,
            minWidth: COL_MIN_WIDTH,
            maxWidth: COL_MAX_WIDTH,
            resizable: true,
            filtersTable,
            sortTable,
            columnsWidth,
            frozen: frozensColumns[dataKey] || false,
            headerRenderer: ({ column }: any) => {
              return (
                <HeaderCell
                  column={column}
                  filters={filtersTable}
                  sort={sortTable}
                  setFrozens={setFrozensColumns}
                />
              );
            },
            cellRenderer: (renderItem: any) => {
              if (checkPercentDataType(dataKey)) {
                return (
                  <span
                    style={{
                      textAlign: "right",
                      width: "100%",
                      paddingRight: "4px",
                    }}
                  >
                    {formatConvertData(renderItem.cellData, dataKey)}
                  </span>
                );
              }
              if (dataKey === "ads_spend") {
                if (renderItem.cellData) {
                  // const formatted = Number(renderItem.cellData).toFixed(2).toString()

                  return (
                    <span
                      style={{
                        textAlign: "right",
                        width: "100%",
                        paddingRight: "4px",
                      }}
                    >
                      {formatMoneyData(renderItem.cellData, currency, dataKey)}
                    </span>
                  );
                }
              }
              if (checkMoneyDataType(dataKey)) {
                return (
                  <span
                    style={{
                      textAlign: "right",
                      width: "100%",
                      paddingRight: "4px",
                    }}
                  >
                    {formatMoneyData(renderItem.cellData, currency, dataKey)}
                  </span>
                );
              }
              if (checkConvertDataType(dataKey)) {
                return (
                  <span
                    style={{
                      textAlign: "right",
                      width: "100%",
                      paddingRight: "4px",
                    }}
                  >
                    {formatConvertData(renderItem.cellData, dataKey)}
                  </span>
                );
              }

              if (checkNativeDataType(dataKey)) {
                return (
                  <span
                    style={{
                      textAlign: "right",
                      width: "100%",
                      paddingRight: "4px",
                    }}
                  >
                    {formatCellData(renderItem.cellData, dataKey)}
                  </span>
                );
              }
              const customStyle: Properties = {
                width: "100%",
                backgroundImage: "unset",
                textAlign: "right",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                height: "38px",
                paddingRight: "4px",
              };
              return getCustomMetricRow(
                dataKey,
                renderItem,
                item,
                currency,
                data
              );
            },
          };
        }),
      ];
    }

    setColumnsTable(_data);
  }, [
    columnsWidth,
    columnWidth,
    metrics,
    isFilling,
    data,
    isCompare,
    groupings,
    widthGroupCol,
    filtersTable,
    sortTable,
    frozensColumns,
    isChartFetching,
  ]);

  useEffect(() => {
    forceUpdate();
  }, [adsGroupsSelected]);

  useEffect(() => {
    if (metrics.findIndex((el) => el.dataKey === "orders_count_2") !== -1) {
      if (!Object.hasOwn(filtersTable, "orders_count_2")) {
        setFiltersTable({
          orders_count_2: {
            value: 0,
            action: "over",
            default: true,
          },
        });
      }
    } else {
      if (Object.hasOwn(filtersTable, "orders_count_2")) {
        setFiltersTable({});
      }
    }

    const _data = finishRows({
      rows: tableData.data,
      filters: filtersTable,
      sort: sortTable,
      percentages,
      totalRow: tableData.total,
      totalChoosenRow: tableData.totalChosen.metrics,
    });
    const temp = _data.map((el: any) => {
      if (el.pathStr === "total") {
        el.pathStr2 = "total";
      } else if (el.group === "empty") {
        el.pathStr2 = uuidv4();
      } else if (el.pathStr === "total_choosen") {
        el.pathStr2 = "total_choosen";
      }
      return { ...el, uniqueKey: uuidv4() };
    }); //adding keys
    setData(temp);
  }, [
    tableData.data,
    tableData.total,
    tableData.totalChosen.metrics,
    filtersTable,
    sortTable,
    percentages,
    expandedRowKeys,
  ]);

  const copyAllTableData = () => {
    const parseData = () => {
      const removeNbsp = (value: string) => {
        return value.replace(/&nbsp;/g, " ").replace(/<[^>]*>/g, "");
      };

      const parseRow = (item: any, prevTitle?: any) => {
        let _row: any = [];

        Object.entries(item).forEach(([key, value]: any) => {
          if (key === "group") {
            if (`${value}`.includes("<a")) {
              let _val: any = `${value}`.split(">");
              _val = _val[1].split("<");
              _val = _val[0];
              _row[0] = prevTitle
                ? `${prevTitle} - ${removeNbsp(_val)}`
                : removeNbsp(_val);
            } else {
              _row[0] = prevTitle
                ? `${prevTitle} - ${removeNbsp(value)}`
                : removeNbsp(value);
            }
          }

          if (
            key !== "id" &&
            key !== "pathStr" &&
            key !== "children" &&
            key !== "clientData"
          ) {
            metrics.forEach((metricItem: any, index: number) => {
              if (key === metricItem.dataKey) {
                if (`${value}`.includes("<a")) {
                  let _val: any = `${value}`.split(">");
                  _val = _val[1].split("<");
                  _val = _val[0];
                  _row[index + 1] = removeNbsp(_val);
                } else {
                  _row[index + 1] = `${value}`.replaceAll(".", ",");
                }
              }
            });
          }

          if (key === "children" && value) {
            if (value[0].group !== "empty") {
              value.forEach((item2: any) => {
                let __row = parseRow(item2, _row[0]);

                let strRow1 = "\n";

                __row.forEach((tempItem) => {
                  tempItem.forEach((item3: any) => {
                    strRow1 += `${removeNbsp(item3)}\t`;
                  });
                });

                _row.push(strRow1);
              });
            }
          }
        });

        return [_row];
      };

      let copyDataStr = "";

      data.forEach((item) => {
        let [_row] = parseRow(item);

        let strRow = "";
        _row.forEach((item: string) => {
          strRow += `${removeNbsp(item)}\t`;
        });
        strRow += "\n";

        copyDataStr += strRow;
      });

      return copyDataStr;
    };

    try {
      let copyDataStr = "";
      let headerRowStr = "";

      groupings.forEach((groupItem: any, index: number) => {
        if (index === 0) {
          headerRowStr += groupItem.label;
        } else {
          headerRowStr += ` - ${groupItem.label}`;
        }
      });

      headerRowStr += "\t";

      metrics.forEach((metricItem: any) => {
        headerRowStr += `${metricItem.label}\t`;
      });

      copyDataStr += `${headerRowStr}\n`;

      copyDataStr += parseData();

      navigator.clipboard
        .writeText(copyDataStr)
        .then(() => {
          message.success({
            content: "Скопировано",
            style: {
              marginTop: "20%",
            },
          });
        })
        .catch(() => {
          message.error({
            content: "Ошибка при копировании данных",
            style: {
              marginTop: "20%",
            },
          });
        });
    } catch (err) {
      message.error({
        content: "Ошибка при копировании данных",
        style: {
          marginTop: "20%",
        },
      });
    }
  };
  const copyAllTableDataLow = () => {
    if (tableData.data.some((el) => el.children.length > 0)) {
      const parseData = () => {
        const removeNbsp = (value: string) => {
          return value.replace(/&nbsp;/g, " ").replace(/<[^>]*>/g, "");
        };

        const parseRow = (item: any, prevTitle?: any) => {
          let _row: any = [];

          Object.entries(item).forEach(([key, value]: any) => {
            if (key === "group") {
              if (`${value}`.includes("<a")) {
                let _val: any = `${value}`.split(">");
                _val = _val[1].split("<");
                _val = _val[0];

                _row[0] = prevTitle
                  ? `${prevTitle} - ${removeNbsp(_val)}`
                  : removeNbsp(_val);
              } else {
                _row[0] = prevTitle
                  ? `${prevTitle} - ${removeNbsp(value)}`
                  : removeNbsp(value);
              }
            }

            if (
              key !== "id" &&
              key !== "pathStr" &&
              key !== "children" &&
              key !== "clientData"
            ) {
              metrics.forEach((metricItem: any, index: number) => {
                if (key === metricItem.dataKey) {
                  if (`${value}`.includes("<a")) {
                    let _val: any = `${value}`.split(">");
                    _val = _val[1].split("<");
                    _val = _val[0];
                    _row[index + 1] = removeNbsp(_val);
                  } else {
                    _row[index + 1] = `${value}`.replaceAll(".", ",");
                  }
                }
              });
            }

            if (key === "children" && value) {
              if (value[0].group !== "empty") {
                value.forEach((item2: any) => {
                  let __row = parseRow(item2, _row[0]);

                  let strRow1 = "\n";

                  __row.forEach((tempItem) => {
                    tempItem.forEach((item3: any) => {
                      strRow1 += `${removeNbsp(item3)}\t`;
                    });
                  });

                  _row.push(strRow1);
                });
              }
            }
          });

          return [_row];
        };

        let copyDataStr = "";

        data.forEach((item) => {
          let [_row] = parseRow(item);
          let strRow = "";
          _row.forEach((item: string) => {
            strRow += `${removeNbsp(item)}\t`;
          });
          strRow += "\n";

          copyDataStr += strRow;
        });

        return copyDataStr;
      };

      try {
        let copyDataStr = "";
        let headerRowStr = "";

        groupings.forEach((groupItem: any, index: number) => {
          if (index === 0) {
            headerRowStr += groupItem.label;
          } else {
            headerRowStr += ` - ${groupItem.label}`;
          }
        });

        headerRowStr += "\t";

        metrics.forEach((metricItem: any) => {
          headerRowStr += `${metricItem.label}\t`;
        });

        copyDataStr += `${headerRowStr}\n`;
        const filterData = (rawData: string) => {
          const lines = rawData.split("\n");
          return lines.filter((line) => {
            const prefix = line.split("\t")[0];
            return !lines.some(
              (otherLine) =>
                otherLine !== line &&
                otherLine.startsWith(prefix) &&
                otherLine.length > line.length
            );
          });
        };

        copyDataStr += filterData(parseData()).join("\n");

        navigator.clipboard
          .writeText(copyDataStr)
          .then(() => {
            message.success({
              content: "Скопировано",
              style: {
                marginTop: "20%",
              },
            });
          })
          .catch(() => {
            message.error({
              content: "Ошибка при копировании данных",
              style: {
                marginTop: "20%",
              },
            });
          });
      } catch (err) {
        message.error({
          content: "Ошибка при копировании данных",
          style: {
            marginTop: "20%",
          },
        });
      }
    } else {
      copyAllTableData();
    }
  };

  const processChildren = (children: any) => {
    children.forEach((el: any) => {
      if (!Array.isArray(el.children)) return;
      if (!el?.clientData?.downloaded) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (downloadedRowKeys.current.includes(el.pathStr2)) {
          return;
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (!downloadedRowKeys.current.includes(el.pathStr2)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          downloadedRowKeys.current = [
            ...new Set([...downloadedRowKeys.current, el.pathStr2]),
          ];
        }
        if (!loadRowKeys.includes(el.pathStr2)) {
          setLoadRowKeys((prev: any) => [...prev, el.pathStr2]);
        }
        if (isCompare) {
          // loadCompareRows(rowData);
        } else {
          setTimeout(() => {
            loadRows(el);
          }, 1000);
        }
        setMetaTableFetching({ value: false });
      } else {
        if (!expandedRowKeys.includes(el.pathStr2)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setExpandedRowKeys((prev: any) => [
            ...new Set([...prev, el.pathStr2]),
          ]);
        }
      }

      if (el.children && el.children.length > 0) {
        processChildren(el.children);
      }
    });
  };
  const handleExpandIconProps = ({ rowData }: any) => {
    try {
      tableData.data.forEach((el, index) => {
        el.children.forEach((el: any) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (expandedAll.includes(tableData.data[index].pathStr2)) {
            if (!rowData?.clientData?.downloaded) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              if (downloadedRowKeys.current.includes(el.pathStr2)) {
                return;
              }
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              if (!downloadedRowKeys.current.includes(el.pathStr2)) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                downloadedRowKeys.current = [
                  ...new Set([...downloadedRowKeys.current, el.pathStr2]),
                ];
              }
              if (!loadRowKeys.includes(el.pathStr2)) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setLoadRowKeys((prev: any) => [
                  ...new Set([...prev, el.pathStr2]),
                ]);
              }
              if (isCompare) {
                // loadCompareRows(rowData);
              } else {
                !isAllExpandedLoading &&
                  setIsAllExpandedLoading({ value: true });
                setTimeout(() => {
                  if (!expandedRowKeys.includes(el.pathStr2)) {
                    loadRows(el);
                  }
                }, 1000);
              }
              setMetaTableFetching({ value: false });
            } else {
              if (!expandedRowKeys.includes(el.pathStr2)) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setExpandedRowKeys((prev: any) => [
                  ...new Set([...prev, el.pathStr2]),
                ]);
              }
            }
            if (Array.isArray(el.children)) {
              processChildren(el.children);
            }
          }
        });
      });
      if (
        rowData.pathStr2 === "total" ||
        rowData.pathStr2 === "total_choosen"
      ) {
        return;
      }
    } catch (err) {
      console.log(err);
    }
  };
  const isSort = () => Object.values(sortTable)[0] === "ASC";
  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={copyAllTableData}>
        Все уровни
      </Menu.Item>
      <Menu.Item key="2" onClick={copyAllTableDataLow}>
        Последний уровень
      </Menu.Item>
    </Menu>
  );
  const renderTable = useMemo(() => {
    return (
      <div className="universal-table" id="universal_table">
        <Loading loading={isTableLoading} hasTip>
          <div
            className="universal-table-settings"
            style={{ width: tableWidth }}
          >
            <div style={{ width: widthGroupCol - 10 }}>
              <GroupingsOrder
                isDisable={isChartLoading}
                hidePersonalDataGroups={hidePersonalDataGroups}
                adsGroupsSelected={adsGroupsSelected}
                adsGroupsAvailable={adsGroupsAvailable}
                setAdsGroupsAvailable={setAdsGroupsAvailable}
                setAdsGroupsSelected={setAdsGroupsSelected}
              />
              <Tooltip
                placement="bottom"
                title={
                  <div
                    className="page-title-tooltip-text"
                    dangerouslySetInnerHTML={{
                      __html:
                        'Группировка — это возможность сгруппировать регистрации пользователей по определённым условиям и посмотреть их данные по 60 метрикам. В SCANе около 70 различных группировок. <a href="https://help.scananalytics.ru/?p=634" rel="noopener noreferrer" target="_blank">Подробнее</a>.',
                    }}
                  ></div>
                }
                color="#fff"
                zIndex={9999}
                trigger="click"
                overlayClassName="page-title-tooltip"
              >
                <QuestionCircleOutlined
                  style={{ color: "#ccc", fontSize: 14, marginLeft: 10 }}
                />
              </Tooltip>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // width: tableWidth - widthGroupCol - 15,
                // width: "100%",
              }}
            >
              <Space direction="horizontal">
                <MetricsOrder
                  isDisable={isChartLoading}
                  adsGroupsSelected={adsGroupsSelected}
                  adsGroupsAvailable={adsGroupsAvailable}
                  setAdsGroupsAvailable={setAdsGroupsAvailable}
                  setAdsGroupsSelected={setAdsGroupsSelected}
                />
                <Tooltip
                  placement="bottom"
                  title={
                    <div
                      className="page-title-tooltip-text"
                      dangerouslySetInnerHTML={{
                        __html:
                          'Метрики — это показатель выбранных группировок. В сервисе около 60 разных показателей. <a href="https://help.scananalytics.ru/?p=636" rel="noopener noreferrer" target="_blank">Подробнее<a/>.',
                      }}
                    ></div>
                  }
                  color="#fff"
                  zIndex={9999}
                  trigger="click"
                  overlayClassName="page-title-tooltip"
                >
                  <QuestionCircleOutlined
                    style={{ color: "#ccc", fontSize: 14 }}
                  />
                </Tooltip>
              </Space>
              <Space direction="horizontal">
                {!isCompare && (
                  <Tooltip
                    title={
                      <div className="page-title-tooltip-text">
                        Заливка ячеек таблицы
                      </div>
                    }
                    color="#fff"
                    zIndex={9999}
                    placement="top"
                  >
                    <Button
                      icon={<BgColorsOutlined />}
                      type={isFilling ? "primary" : "text"}
                      disabled={isChartLoading}
                      onClick={() => {
                        setIsFilling((prev) => !prev);
                      }}
                    />
                  </Tooltip>
                )}
                <Tooltip
                  title={
                    <div className="page-title-tooltip-text">
                      Копирование данных таблицы в буфер обмена, чтобы можно
                      было вставить данные в Excel или Гугл-таблицы.
                    </div>
                  }
                  color="#fff"
                  zIndex={9999}
                  placement="topLeft"
                >
                  <Dropdown overlay={menu} trigger={["click"]}>
                    <Button
                      icon={<CopyOutlined />}
                      type="text"
                      disabled={isChartLoading}
                    />
                  </Dropdown>
                </Tooltip>
                <Tooltip
                  placement="bottom"
                  title={
                    <div
                      className="page-title-tooltip-text"
                      dangerouslySetInnerHTML={{
                        __html:
                          'Таблица позволяет отобразить данные по 70 различным группировкам и 60 метрикам. Так же можно одну группировку вложить в другую и таких уровней можно сделать неограниченное количество. <a href="https://help.scananalytics.ru/?p=606" rel="noopener noreferrer" target="_blank">Подробнее<a/>.',
                      }}
                    ></div>
                  }
                  color="#fff"
                  zIndex={9999}
                  trigger="click"
                  overlayClassName="page-title-tooltip"
                >
                  <QuestionCircleOutlined
                    style={{ color: "#ccc", fontSize: 14 }}
                  />
                </Tooltip>
              </Space>
            </div>
          </div>

          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <BaseTable
            fixed
            columns={columnsTable}
            data={data}
            rowKey="pathStr2"
            width={tableWidth}
            maxHeight={dimensionsTable.height}
            minHeight={300}
            headerHeight={
              Object.keys(filtersTable).length > 0
                ? HEADER_HEIGHT + 15
                : HEADER_HEIGHT
            }
            estimatedRowHeight={70}
            expandColumnKey="group"
            expandIconProps={({ rowData }: any) => {
              handleExpandIconProps({ rowData });

              return {
                expanded: expandedRowKeys.includes(rowData.pathStr2),
                pathStr2: rowData.pathStr2,
                expandable: !!rowData.children,
                loading: downloadedRowKeys.current.includes(rowData.pathStr2)
                  ? false
                  : loadRowKeys.includes(rowData.pathStr2),
                downloaded: rowData.clientData && rowData.clientData.downloaded,
              };
            }}
            onRowExpand={({ expanded, rowData, rowKey }: any) => {
              if (!rowData.clientData.downloaded) {
                if (!loadRowKeys.includes(rowData.pathStr2)) {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  setLoadRowKeys((prev: any) => [
                    ...new Set([...prev, rowKey]),
                  ]);
                  if (isCompare) {
                    loadCompareRows(rowData);
                  } else {
                    loadRows(rowData);
                  }

                  setMetaTableFetching({ value: false });
                }
              } else {
                if (expanded)
                  setExpandedRowKeys((prev: any) => [...prev, rowKey]);
                else
                  setExpandedRowKeys((prev: any) =>
                    prev.filter((item: any) => item !== rowKey)
                  );
              }
            }}
            expandedRowKeys={expandedRowKeys}
            components={{
              ExpandIcon: (props) => {
                return (
                  <ExpandIcon {...props} setExpandedAll={setExpandedAll} />
                );
              },
            }}
            disabled={isTableLoading}
            rowRenderer={({ rowData, cells }: any) => {
              const customStyle = {
                height: "38px",
              };
              if (rowData.group === "empty") {
                rowData.pathStr2 = uuidv4();
                const parent = findObjectById(data, rowData.parentId);
                if (parent) {
                  const parentName: string = findTopParentObjectName(
                    groupings[parent.clientData.path.length].name
                  );
                  return (
                    <>
                      <div
                        className="table-row-empty"
                        style={{ ...customStyle, paddingTop: "10px" }}
                      >
                        <span
                          style={{
                            color: "rgb(196,196,196",
                            fontSize: "9px",
                            minWidth: "120px",
                            position: "absolute",
                            top: 0,
                            left: 10,
                          }}
                        >
                          {groupings[parent.clientData.path.length].name}
                        </span>
                        <Empty
                          style={{ paddingTop: 16 }}
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={false}
                          imageStyle={{ height: 27, marginBottom: 0 }}
                        />
                        {parentName ||
                          "Нет данных, соответствующих условиям отчёта."}
                      </div>
                    </>
                  );
                } else {
                  return (
                    <div className="table-row-empty" style={customStyle}>
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={false}
                        imageStyle={{ height: 27, marginBottom: 0 }}
                      />
                      Нет данных, соответствующих условиям отчёта.
                    </div>
                  );
                }
              }
              if (
                rowData.pathStr2 === "total" ||
                rowData.pathStr2 === "total_choosen"
              ) {
                return (
                  <div
                    className="BaseTable__row BaseTable__header-row"
                    style={customStyle}
                  >
                    {cells}
                  </div>
                );
              }
              return (
                <div className="BaseTable__row" style={customStyle}>
                  {cells}
                </div>
              );
            }}
          />
        </Loading>
      </div>
    );
  }, [
    isTableLoading,
    widthGroupCol,
    hidePersonalDataGroups,
    adsGroupsSelected,
    adsGroupsAvailable,
    isSort,
  ]);
  return renderTable;
};

export default UniversalTable;
