import { listKeysGroupings } from "../../shared/common/components/reports/GroupingsAD"
import { listKeysMetrics } from "../../shared/common/components/reports/MetricsAD"
import FirstRegIcon from "../../shared/common/components/reports/params/attribution/icons/FirstRegIcon"
import LastRegIcon from "../../shared/common/components/reports/params/attribution/icons/LastRegIcon"
import TypeIcon from "../../shared/common/components/reports/params/attribution/icons/TypeIcon"

export const defaultMetricsOrderNotPrepare = [ // Дефолтные метрики
    "regs_count",
    "users_count",
    "orders_count",
    "payments_count",
    "payments_sum",
    "user_to_buyer_converse",
    "ads_spend",
    "profit",
    "romi"
].map((dataKey) => listKeysMetrics.find((item: any) => item.dataKey === dataKey))

export const defaultMetricsAd = defaultMetricsOrderNotPrepare.map((item) => {
    return { ...item }
})

const defaultGroupingsOrderNotPrepare = [ // Дефолтные группировки
    "ads_source",
    "ads_campaign",
    "ads_adv_id",
].map((dataKey) => listKeysGroupings.find((item: any) => item.dataKey === dataKey))

export const defaultGroupingsAd = defaultGroupingsOrderNotPrepare.map((item) => {
    return { ...item }
})

const defaultMetricsAdNotPrepareNumber = [
    "ads_spend",
].map((dataKey) =>
    listKeysMetrics.find((item: any) => item.dataKey === dataKey)
);

export const defaultMetricAd = defaultMetricsAdNotPrepareNumber
    .filter((item) => item)
    .map((item) => ({
        ...item,
        dataKey: item.dataKey,
    }));


const defaultGroupingsAdNotPrepareNumber = [
    "ads_date_y",
].map((dataKey) =>
    listKeysGroupings.find((item: any) => item.dataKey === dataKey)
);

export const defaultGroupingAd = defaultGroupingsAdNotPrepareNumber
    .filter((item) => item)
    .map((item) => ({
        ...item,
        dataKey: item.dataKey,
    }));

export const attrItems = [
    {
        id: "type_applications",
        name: "Тип регистрации:",
        selectValues: [
            { name: "Нулевые заказы GC", id: "2", icon: null },
            { name: "Прямая продажа", id: "4", icon: null },
            { name: "Регистрации GC", id: "0", icon: null },
            { name: "SCAN", id: "1", icon: null },
            //{ name: "Ручное добавление", id: "3", icon: null },
        ],
        description: `Выбор типа регистрации по которым нужно создать отчёт:
                <br><br>
                Типы регистраци:
                <br><br>
                - SCAN - Это данные собираемые скриптом SCAN с форм;<br>
                - Прямая продажа - эта регистрация создаётся автоматически в SCANе при создании платного заказа. Название регистрации будет являться название платного предложения;<br>
                - Нулевые заказы GC - Заказы в GetCourse со стоимостью 0 руб.;<br>
                - Регистрации GC -  Первая регистрация (попадания в базу) в GetCourse;<br>
                <br><br>
                <a href="https://help.scananalytics.ru/?p=504" rel="noopener noreferrer" target="_blank">Подробнее</a>.
                `,
        icon: <TypeIcon />,
    },
    {
        id: "attribution",
        name: "Атрибуция:",
        selectValues: [
            { name: "Первая регистрация", id: "1", icon: <FirstRegIcon /> },
            { name: "Последняя регистрация", id: "2", icon: <LastRegIcon /> },
        ],
        description: `Сервис SCAN позволяет определить к какой регистрации пользователя привязывать заказ. В сервисе 2 типа атрибуции:
                <br><br>
                - Первая регистрация <br>
                - Последняя регистрация <br>
                <br><br>
                <a href="https://help.scananalytics.ru/?p=508" rel="noopener noreferrer" target="_blank">Подробнее</a>.
                `,
        icon: null,
    },
]

export const filtersFilter = [
    "Пользователи",
    "Регистрации",
    "Заявки",
    "Заказы",
    "Платежи",
    "Вебинары",
    "Реклама",
    "Просмотр страницы после регистрации"
]
