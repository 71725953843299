import axios, { AxiosResponse } from "axios"

import { API_SEND_HIT } from "../urls"

export const logsApi = {
    sendHit: (hit: string, location: string, type: number): Promise<AxiosResponse | void> | undefined => {
        try {
            const token = localStorage.getItem('access') || sessionStorage.getItem('access');
            const isAdmin = localStorage.getItem("access_admin") === 'true';
            
            if (!token || isAdmin) {
                return;
            }

            const sanitizedLocation = new URL(location).toString();

            const data = {
                type: Number(type),
                url: sanitizedLocation,
                hit: hit.replace(/[^a-zA-Z0-9-]/g, '') 
            }
            
            return axios.post(API_SEND_HIT, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).catch(err => {
                if (err.response?.status !== 401) {
                    console.error('Hit logging error:', err.message);
                }
                return;
            });
            
        } catch (error) {
            console.error('Hit logging error:', error instanceof Error ? error.message : 'Unknown error');
            return;
        }
    },
}
