import axios, { AxiosResponse } from "axios"

import { axiosConfigAuth } from "../middleware"
import { API_GET_TG_LINK, API_UPDATE_USER_CONTACTS, API_UPDATE_USER_PASSWORD } from "../urls"

export const profileApi = {
    updateProfileData: (values: any): Promise<AxiosResponse> => {
        return axios.post(API_UPDATE_USER_CONTACTS, values, axiosConfigAuth())
    },

    changePassword: (values: any): Promise<AxiosResponse> => {
        return axios.post(API_UPDATE_USER_PASSWORD, values, axiosConfigAuth({},{withCredentials: true}))
    },

    getConnectTelegramLink: (): Promise<AxiosResponse> => {
        return axios.get(API_GET_TG_LINK, axiosConfigAuth())
    }
}
