import axios, { AxiosError, AxiosRequestConfig } from "axios"
import { bindActionCreators } from "redux"

import { authApi } from "./endpoints/authApi"
import { rootAction } from "../../../app/providers/redux/root"
import { TAppDispatch } from "../../../app/providers/redux/store"
import {
    clearStorage,
    getAccessToken,
    getFingerPrint,
    saveAccessToken,
} from "../../../helpers/utils/auth"
import { showErrorMessage, showErrorNotification } from "../../../helpers/utils/ui"
import JSONbig from "json-bigint";
import { routeNames } from "../constants/routes"
import { config } from "process"

export const axiosConfigAuth = (
    headers?: any,
    options?: any
): AxiosRequestConfig => {
    return {
        headers: {
            Token: `${getAccessToken()}`,
            ...headers,
        },
        ...options,
        transformResponse: [(data) => {
        try {
            return JSONbig.parse(data);
        } catch (e) {
            console.error('Ошибка при парсинге данных:', e);
            return data;
        }
    }]
    }
}

export const axiosRefreshConfig = (options?: any): AxiosRequestConfig => {
    return {
        withCredentials: true,
        ...options,
    }
}

export const handleResponseError = (
    dispatch: TAppDispatch,
    error: AxiosError,
    messages?: { [key: number]: string }
) => {
    const { authLogout, setAppGlobalError } = bindActionCreators(
        rootAction,
        dispatch
    )

    if (error.response) {
        if (error.response?.status === 404) {
            setAppGlobalError(true)
            return
        }

        if (error.response?.status === 401) {
            setAppGlobalError(false)
            return
        } else if (error.response?.status === 500) {
            setAppGlobalError(true)
            return
        }

        Object.entries(messages || []).forEach(([ key, val ]) => {
            if (`${error.response?.status}` === key) {
                showErrorMessage(val)
                return
            }
        })

        return
    }
}

export const initAuthInterceptor = async () => {
    const fingerprint = await getFingerPrint()
    let authCommon:{[key:string]: any} = {isRefreshing: false, requestWaiting: []}

    axios.interceptors.request.use(
        async (config) => {
            const accessToken = getAccessToken()

            if (config.url?.includes("logout")) {
                return config
            }

            if (config.url?.includes("refresh-token")) {
                return config
            }

                config.headers.Token = accessToken

            return config
        },
        (error) => {
            return Promise.resolve(error)
        }
    )
    axios.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            const originalConfig = err.config;

            if (!originalConfig.url?.includes("logout") && !originalConfig.url?.includes("refresh-token") && err.response) {
            if (err.response.status === 401 && !originalConfig._retry) {
                const requestPromise = new Promise((res,rej) =>{
                    authCommon.requestWaiting.push({resolve: res, reject:rej, config: originalConfig})
                })
                originalConfig._retry = true;
                if(!authCommon.isRefreshing){
                authCommon.isRefreshing = true;
                try {
                await authApi
                    .refreshToken(fingerprint)
                    .then((response) => {
                        const newAccessToken = response.data.access_token
                        saveAccessToken(newAccessToken)
                        authCommon.requestWaiting.map(async (request:any) => {
                            axios(request.config)
                                .then((response)=>{
                                    request.resolve(response)
                                })
                                .catch((error)=>{
                                    request.reject(error)
                                })
                        })
                    })
                    .catch((error) => {
                    clearStorage();
                    authApi
                            .logout(getAccessToken())
                            .catch((err) => {
                                console.error(err)
                            })
                            .finally(() => {
                                showErrorNotification("Сессия истекла. Пожалуйста, войдите снова.")
                                setTimeout(()=>window.location.href = window.location.origin + routeNames.login,2000)
                            })
                    })
                    .finally(()=>{
                        authCommon.isRefreshing = false
                        authCommon.requestWaiting = []

                    })
                    } catch (_error) {
                        return Promise.reject(_error);
                    }
                }
                return requestPromise
            }
            }

            return Promise.reject(err);
        }
)
}
