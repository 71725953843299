import { NavigateFunction } from "react-router"

import { currentUserSliceActions } from "./currentUserSlice"
import { profileApi } from "../../../../../shared/common/api/endpoints/profileApi"
import { schoolApi } from "../../../../../shared/common/api/endpoints/schoolApi"
import { handleResponseError } from "../../../../../shared/common/api/middleware"
import { routeNames } from "../../../../../shared/common/constants/routes"
import { getFingerPrint, parseUserRole, saveAccessToken } from "../../../../../helpers/utils/auth"
import {
    showErrorMessage,
    showSuccessNotification,
} from "../../../../../helpers/utils/ui"
import { TAppDispatch } from "../../store"
import { appActions } from "../app/actions"
import { currentSchoolActions } from "../currentSchool/actions"
import {staffApi} from "../../../../../shared/common/api/endpoints/staffApi";

export const currentUserActions = {
    ...currentUserSliceActions,

    fetchUserAndSchoolInfo:
    (navigate: NavigateFunction, onFinish?: () => void) =>
        (dispatch: TAppDispatch) => {
            dispatch(appActions.setAppLoading(true))

            schoolApi
                .fetchAllData()
                .then((response) => {
                    if (response.status === 200) {
                        const data = response.data
                        const schoolData = {
                            selectedSchool: data.selected_school || null,
                            schoolList: data.school_all || null,
                            userAccess: {...data.access,telegram_connect:data.telegram_connect} || null,
                        }
                        dispatch(currentUserActions.setContact(data.contact))
                        if(schoolData.selectedSchool){
                            if(schoolData.selectedSchool?.id){
                                try{
                                    staffApi.getCurrentStaff(schoolData.selectedSchool?.id,data.id).then((response)=>{
                                        if (response.status === 200) {
                                            const newValues = Object.entries(response.data).map(([ key, val ]) => {
                                                if ((key === "ads" || key === "offers") && (val === null || val === undefined)) {
                                                    return { name: key, value: [] }
                                                }

                                                return { name: key, value: val }
                                            })

                                            if(newValues.some((element)=>element.name.includes("utm"))){
                                                if(location.pathname.includes("users") && location.pathname !== '/users/universal' && location.pathname !== "/users/telegram"){
                                                    // redirects when /users/universal window.location.pathname = '/users/universal'
                                                }
                                                dispatch(
                                                    currentUserActions.setUtm(true))
                                            }else{
                                                dispatch(
                                                    currentUserActions.setUtm(false))
                                            }
                                        }
                                    })
                                }catch(err){
                                    console.log(err)
                                }
                            }
                            if (
                                schoolData.selectedSchool === null &&
        !window.location.href.includes(routeNames.schoolAdd)
        && !window.location.href.includes(routeNames.partner) && !window.location.href.includes(routeNames.error)
                            ) {
                                navigate(routeNames.schoolAdd)
                                return
                            }

                            const role = parseUserRole(data.role, data.setting, data.contact)

                            dispatch(
                                currentUserActions.currentUserSetData({
                                    id: data.id,
                                    name: data.name,
                                    email: data.email,
                                    phone: data.phone,
                                    role: role,
                                    isLoading: false,
                                })
                            )
                            dispatch(currentSchoolActions.currentSchoolSetData(schoolData))
                            const isPaymentStatus = !window.location.href.includes(routeNames.paymentSuccess) && !window.location.href.includes(routeNames.paymentError)
                            const isPaymentPage = window.location.pathname === '/payment'

                            /*
                            if (
                                !schoolData.userAccess.status
                                && !window.location.href.includes(routeNames.noAccess)
                                && isPaymentStatus && !isPaymentPage
                            ) {
                                // navigate(routeNames.noAccess); // на страницу noAccess
                                navigate(routeNames.bill) // на страницу billing
                            }
                             */

                            if (
                                data.telegram_connect === false
                            ) {
                                navigate(routeNames.telegramConnect)
                            }

                            onFinish && onFinish()
                        }else{
                            if (
                                data.telegram_connect === false
                            ) {
                                navigate(routeNames.telegramConnect)
                            }
                            else {
                                (
                                    !window.location.href.includes(routeNames.schoolAdd)
                                    && !window.location.href.includes(routeNames.partner)
                                )
                                {
                                    navigate(routeNames.schoolAdd)
                                }
                            }
                            const role = parseUserRole(data.role, data.setting, data.contact)

                            dispatch(
                                currentUserActions.currentUserSetData({
                                    id: data.id,
                                    name: data.name,
                                    email: data.email,
                                    phone: data.phone,
                                    role: role,
                                    isLoading: false,
                                })
                            )
                            dispatch(currentSchoolActions.currentSchoolSetData(schoolData))


                            onFinish && onFinish()
                            return
                        }
                    }
                })
                .catch((err) => {
                    handleResponseError(dispatch, err)
                    navigate("/")
                })
                .finally(() => {
                    dispatch(appActions.setAppLoading(false))
                })
        },

    updateUserInfo:
    (values: any, onFinish?: () => void) => (dispatch: TAppDispatch) => {
        dispatch(currentUserActions.userSetLoading(true))

        profileApi
            .updateProfileData(values)
            .then((response) => {
                if (response.status === 200) {
                    showSuccessNotification("Данные обновлены.")
                    onFinish && onFinish()
                }
            })
            .catch(() => {
                showErrorMessage("Ошибка при обновлении данных.")
            })
            .finally(() => {
                dispatch(currentUserActions.userSetLoading(false))
            })
    },

    updateUserPassword:
    (values: any, onFinish?: () => void) => async (dispatch: TAppDispatch) => {
        dispatch(currentUserActions.userSetLoading(true))
        const fingerprint = await getFingerPrint()
        profileApi
            .changePassword({...values, fingerprint})
            .then((response) => {
                if (response.status === 200) {
                    showSuccessNotification("Пароль обновлен.")
                    onFinish && onFinish()
                }
                if(response.data?.access_token){
                    saveAccessToken(response.data.access_token)
                }
            })
            .catch(() => {
                showErrorMessage("Ошибка при обновлении данных.")
            })
            .finally(() => {
                dispatch(currentUserActions.userSetLoading(false))
            })
    },
}
