import React, { FC, useEffect, useMemo, useRef, useState } from "react"

import {
    FilterOutlined,
    PercentageOutlined,
    AlignLeftOutlined,
    CloseOutlined,
    PushpinOutlined,
    VerticalRightOutlined,
    VerticalLeftOutlined,
    RiseOutlined, QuestionCircleOutlined, EditOutlined,
} from "@ant-design/icons"
import { Button, Input, InputNumber, Popover, Radio, Space, Tag, Tooltip } from "antd"
import classNames from "classnames"

import { getCookie } from "../../../../../../helpers/utils/cookies"
import { isNumeric } from "../../../../../../helpers/utils/functions"
import { showErrorMessage, showSuccessMessage } from "../../../../../../helpers/utils/ui"
import { unApi } from "../../../../api/endpoints/reports/unApi"
import { useActions } from "../../../../hooks/useActions"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"
import { customIcons } from "../../../Icons/customIcons"
import EditMetricsModal from "../../EditMetricsModal"
import FilterTag from "../../FilterTag"
import { declineCumulativeMetrics } from "../../Metrics"
import MetricsSelectModal from "../../un-chart/components/bubble-chart/MetricsSelectModal"

const FilterPopover: FC<any> = ({ filters, dataKey, setVisible,setVisibleFilterColumn,blockId }) => {

    const [ value, setValue ] = useState(() => {
        if (!filters[dataKey]) return null
        return filters[dataKey].value
    })
    const [ action, setAction ] = useState(() => {
        if (!filters[dataKey]) return "equal"
        return filters[dataKey].action
    })

    const refInput: any = useRef()

    const { setFiltersTable } = useActions()
    const handleApply = () => {
        let newFilters = { ...filters }
        newFilters[dataKey] = { value, action }
        if(blockId){
            setFiltersTable({ data:newFilters,blockId })
        }else{
            setFiltersTable(newFilters)
        }
        setVisibleFilterColumn((prev:boolean)=>prev? prev : !prev)
        setVisible(false)
    }
    const handleInputChange = (num: number | null) => {
        num && Number.isInteger(num) && !(action !== "equal") ?setValue(num.toFixed(2)):setValue(num)
    }
    const resetFilter = () => {
        setValue(null)
        setAction("equal")

        let newFilters = { ...filters }
        delete newFilters[dataKey]
        if(blockId){
            setFiltersTable({ data:newFilters,blockId })
        }else{
            setFiltersTable(newFilters)
        }
        setVisible(false)
    }

    return (
        <div>
            <Space direction="vertical">
                <Radio.Group value={action} onChange={(e) => setAction(e.target.value)}>
                    <Space direction="vertical">
                        <Radio value="less">Меньше</Radio>
                        <Radio value="equal">Равно</Radio>
                        <Radio value="over">Больше</Radio>
                    </Space>
                </Radio.Group>
                <Input.Group compact>
                    <InputNumber
                        ref={refInput}
                        value={value}
                        onChange={handleInputChange}
                        onPressEnter={handleApply}
                        step={1}
                        style={{ width: 78 }}
                    />
                    <Button onClick={resetFilter} icon={<CloseOutlined />} />
                </Input.Group>

                <Button onClick={handleApply} title="Применить (Enter)">
                    Применить
                </Button>
            </Space>
        </div>
    )
}

const PinPopover: FC<any> = ({ dataKey, setVisible, setFrozens }) => {
    const handlePinLeft = () => {
        setFrozens((prev: any) => {
            let newData = { ...prev }
            newData[dataKey] = "left"
            return newData
        })
        setVisible(false)
    }
    const handlePinRight = () => {
        setFrozens((prev: any) => {
            let newData = { ...prev }
            newData[dataKey] = "right"
            return newData
        })
        setVisible(false)
    }
    const handlePinReset = () => {
        setFrozens((prev: any) => {
            let newData = { ...prev }
            delete newData[dataKey]
            return newData
        })
        setVisible(false)
    }

    return (
        <div>
            <Space size="small">
                <Button onClick={handlePinLeft} icon={<VerticalRightOutlined />} size="small" title="Закрепить слева" />
                <Button onClick={handlePinReset} icon={<CloseOutlined />} size="small" title="Сбросить закрепление" />
                <Button onClick={handlePinRight} icon={<VerticalLeftOutlined />} size="small" title="Закрепить справа" />
            </Space>
        </div>
    )
}

const HeaderCell: FC<any> = ({ column, filters, sort, setFrozens, disabled = false,item,blockId }) => {
    const { dataKey, title, description } = column
    const [ editMetricsState,setEditMetricsState ] = useState<{ visible:boolean,id:null | number,type:string | null }>({
        visible:false,
        type:null,
        id:dataKey.replace(/_(\d+?)_/g, "$1")
    })
    const { dashboards } = useTypedSelector((state)=>state.dashboard)
    const currentBlock = dashboards.find((el)=>{
        return el?.data?.blockId === blockId
    })
    const isChartChosen = !blockId ? getCookie("chosenDisplay") === "chart" : currentBlock.componentType === "chart"
    const isNumberChosen = !blockId ?  getCookie("chosenDisplay") === "number" : currentBlock.componentType === "number"
    const { selectedSchool } = useTypedSelector((state)=>state.currentSchool)
    const [ visibleFilter, setVisibleFilter ] = useState(false)
    const [ visiblePin, setVisiblePin ] = useState(false)
    const [ visibleMetricSelect, setVisibleMetricSelect ] = useState(false)
    const [ visibleShowChart,setVisibleShowChart ] = useState(true)
    const [ visibleFilterColumn,setVisibleFilterColumn ] = useState(false)
    const [ visibleTargetMetric,setVisibleTargetMetric ] = useState(false)
    const [ visibleCumulativeChart,setVisibleCumulativeChart ] = useState(false)
    const [ visiblePinFilter,setVisiblePinFilter ] = useState(false)
    const [ visibleMorePopovers, setVisibleMorePopovers ] = useState(false)
    const [ checkedMetric, setCheckedMetric ] = useState(false)
    const { chartType } = useTypedSelector((state) => state.chart)
    const { percentages, metricsChecked, metricsLimit,groupsChecked,metrics } = useTypedSelector((state) => blockId ? state.table.blocks[blockId] : state.table)
    const [ visibleAbsoluteValue,setVisibleAbsoluteValue ] = useState(percentages?.includes(dataKey))
    const { isCompare } = useTypedSelector((state) => state.meta)
    const { isChartVisible } = useTypedSelector((state)=>state.chart)
    const {
        setCumulativeChart,
        setMetricsChecked,
        setMetaChartFetching,
        setMetaCompareChartFetching,
        setSortTable,
        setPercentages,
        setFiltersTable,
        setChartVisible,
        getCreatedMetrics,
        setMetrics,
        setIsNotParamsFilterVisible,
        setAllDataFetching,
    } = useActions()
    useEffect(()=>{
        setIsNotParamsFilterVisible(editMetricsState.visible)
        if(editMetricsState.type === "save"){
            setAllDataFetching({ value:true })
        }
    },[ editMetricsState.visible ])
    const isFiltered = filters[dataKey] && "primary" && !filters[dataKey]?.default
    const isPercentage = percentages.includes(dataKey) ? "primary" : "default"

    const cumulativeDataKey = `${dataKey?.startsWith("_") ? dataKey?.slice(0,-1) : dataKey}_cumulative`

    useEffect(() => {
        const _val = !!metricsChecked.find((item: any) => item ? item.name === dataKey : undefined)
        setCheckedMetric(_val)
    }, [ metricsChecked, dataKey ])

    const displayCumulativeChart = () => {

        if (cumulativeCheckedMetric) {
            setCumulativeChart(false)
            setMetricsChecked({ data:[ ...metricsChecked.filter((item) => item.name !== cumulativeDataKey) ] })
        } else {
            setCumulativeChart(true)
            setMetricsChecked({ data:[ ...metricsChecked.filter((item) => item.name !== dataKey), { name: cumulativeDataKey, label: title,plan:visibleTargetMetric ? column?.plan : null,base_metric_name:column?.base_metric_name,metric_formula:column?.metric_formula } ] })
        }

        setTimeout(() => {
            setMetaChartFetching({ value:true })
            setMetaCompareChartFetching({ value:true })
        }, 50)
    }

    const handleSort = () => {
        if (disabled) return
        let newSort: any = {}
        if (sort[dataKey] === "ASC") newSort[dataKey] = "DESC"
        else newSort[dataKey] = "ASC"

        if (sort[dataKey] === "DESC") {
            setSortTable({ data:{},blockId })
        } else {
            setSortTable({ data:newSort,blockId })
        }
    }

    const handlePercentages = () => {
        if (percentages.includes(dataKey)) {
            if(blockId){
                setPercentages({ data:percentages.filter((item) => item !== dataKey),blockId })
            }else{
                setPercentages(percentages.filter((item) => item !== dataKey))
            }
        } else {
            if(blockId){
                setPercentages({ data:[ ...percentages, dataKey ],blockId })
            }else{
                setPercentages([ ...percentages, dataKey ])
            }
        }
    }

    const cumulativeCheckedMetric =
        useMemo(() => !!metricsChecked.find((item: any) => item ? item.name === cumulativeDataKey : undefined), [ metricsChecked, cumulativeDataKey ])
    const hideCumulativeMetric =
        useMemo(() => !!declineCumulativeMetrics.find((item: any) => item === dataKey), [ declineCumulativeMetrics, dataKey ])

    const handleCheckedMetric = () => {
        if(!isChartVisible){
            localStorage.setItem("isChartVisible",`${!isChartVisible}`)
            setChartVisible()
        }
        if (!checkedMetric) {
            if (metricsLimit) {
                groupsChecked.length === 1? setMetricsChecked({ data:[ ...metricsChecked, { name: dataKey, label: title } ] }) :setMetricsChecked({ data:[ { name: dataKey, label: title } ] })
            } else if ([ "pie", "area" ].includes(chartType)) {
                setMetricsChecked({ data:[ { name: dataKey, label: title } ] })
            } else {
                if (cumulativeCheckedMetric) {
                    setMetricsChecked({ data:[ ...metricsChecked.filter((item) => item.name !== cumulativeDataKey), { name: dataKey, label: title,base_metric_name:column?.base_metric_name,metric_formula:column?.metric_formula } ] })
                } else {
                    setMetricsChecked({ data:[ ...(isNumberChosen ?  [] : metricsChecked), { name: dataKey, label: title,plan:visibleTargetMetric ? column?.plan : null,base_metric_name:column?.base_metric_name,metric_formula:column?.metric_formula } ] })
                }
            }
        } else {
            setMetricsChecked({ data:metricsChecked.filter((item) => item.name !== dataKey) })
        }

        setTimeout(() => {
            setMetaChartFetching({ value:true })
            setMetaCompareChartFetching({ value:true })
        }, 50)
    }
    const onRemoveFilter = () => {
        let newFilters = { ...filters }
        delete newFilters[dataKey]
        if(blockId){
            setFiltersTable({ data:newFilters,blockId })

        }else{
            setFiltersTable(newFilters)
        }
    }
    useEffect(()=>{
        if(!isFiltered){
            setVisibleFilterColumn(false)
        }
    },[ isFiltered ])
    const tableHeaderCellRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (tableHeaderCellRef.current) {
            if (tableHeaderCellRef.current && tableHeaderCellRef.current.parentElement && column?.background) {
                tableHeaderCellRef.current.parentElement.style.backgroundColor = column?.background
            }
        }
    },)
    useEffect(()=>{
        if(blockId && metrics.length > 0){
            setSortTable({ data:{ [metrics[0].dataKey]:"DESC" },blockId })
        }
    },[ metrics ])
    const confirmCreatedMetricsRemoving = async (nodeData:any) => {
        try{
            await unApi.removeCustomMetric(nodeData.id,location.pathname)
            if(selectedSchool?.id){
                setTimeout(()=>getCreatedMetrics(selectedSchool.id),150)
            }
            setMetrics({ data:metrics.filter((el:any)=>el.dataKey !== `_${nodeData.dataKey}_`) })
            setEditMetricsState(prev=>({ ...prev,visible:false }))
            showSuccessMessage("Метрика успешно удалена.")
        }catch(err){
            showErrorMessage("Произошла ошибка во время удаления метрики.")
            console.log(err)
        }
    }

    useEffect(() => {
        if (isNumberChosen && !blockId) {
            handleCheckedMetric();
        }
    }, [isNumberChosen]);
    
    const getTitle = () => {
        let title = "Отобразить на графике"
        if(isNumberChosen && !blockId){
            title = "Отобразить как число"
        }
        return title
    }

    return (
        <div className="table-header-cell" ref={tableHeaderCellRef}>
            <div onClick={handleSort}>
                <div
                    className={classNames("table-header-sort", "table-header-withIcon", {
                        "sort-asc": sort[dataKey] === "ASC",
                        "sort-desc": sort[dataKey] === "DESC",
                    })}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%"
                        }}
                        className="header-cell_w-icon"
                    >
                        {
                            description?.length > 1 && <span
                                style={{ marginRight: 5, marginLeft: 5 }}
                            >
                                  <Tooltip
                                      placement="bottom"
                                      title={<div className="page-title-tooltip-text" dangerouslySetInnerHTML={{ __html: description }}></div>}
                                      color="#fff"
                                      zIndex={9999}
                                      overlayClassName="page-title-tooltip"
                                  >
                                      <QuestionCircleOutlined style={{ color: "#ccc", fontSize: 13 }}/>
                                  </Tooltip>
                            </span>
                        }
                        <span className="group-item-icon-wrapper">
                            {title}
                        </span>
                    </div>
                </div>
            </div>
            <Space size={5} className="table-header-cell-buttons">
                {
                    isCompare ? null :
                        visibleFilterColumn  ? (
                            <Popover
                                content={<FilterPopover blockId={blockId} filters={filters} dataKey={dataKey} setVisible={setVisibleFilter} setVisibleFilterColumn={setVisibleFilterColumn} />}
                                trigger="click"
                                open={visibleFilter}
                                onOpenChange={setVisibleFilter}
                                placement="bottom"
                            >
                                <Button
                                    type={isFiltered ? "primary" : "default"}
                                    icon={<FilterOutlined />}
                                    size="small"
                                    title="Фильтровать по колонке"
                                    disabled={disabled}
                                />
                            </Popover>
                        ) : null
                }
                {
                    column.dataKey.startsWith("_") && isNumeric(column?.plan) && isChartChosen && visibleTargetMetric &&
                    <Button
                        type={visibleTargetMetric ? "primary" : "default"}
                        icon={customIcons.targetSimpleIcon({ style:{ fill:"white",marginTop:"4px" } })}
                        size="small"
                        title="Отобразить план"
                        disabled={disabled}
                        onClick={()=>{
                            setVisibleMorePopovers(false)
                            setVisibleTargetMetric(false)
                            if(visibleCumulativeChart){
                                setMetricsChecked([ ...metricsChecked.filter((item) => item.name !== dataKey), { name: cumulativeDataKey, label: title,plan:null,base_metric_name:column?.base_metric_name,metric_formula:column?.metric_formula } ])
                            }else {
                                setMetricsChecked([ ...metricsChecked.filter((item) => item.name !== dataKey), { name: dataKey, label: title,plan:null,base_metric_name:column?.base_metric_name,metric_formula:column?.metric_formula } ])
                            }
                        }}
                    />
                }
                {
                    hideCumulativeMetric ? null :
                        visibleAbsoluteValue ? (
                            <Button
                                onClick={()=>{
                                    handlePercentages()
                                    setVisibleMorePopovers(false)
                                    setVisibleAbsoluteValue((prev)=>!prev)
                                }}
                                type={isPercentage}
                                icon={<PercentageOutlined/>}
                                size="small"
                                title="Абсолютные значения / Проценты"
                                disabled={isCompare || disabled}
                            />
                        ):null
                }
                {
                    chartType === "bubble" && isChartChosen ?
                        <Popover
                            content={<MetricsSelectModal dataKey={dataKey} setVisible={setVisibleMetricSelect}/>}
                            trigger="click"
                            open={visibleMetricSelect}
                            onOpenChange={setVisibleMetricSelect}
                            placement="topLeft"
                            title={"Выберите ось:"}
                        >
                            <Button
                                icon={<AlignLeftOutlined rotate={-90}/>}
                                size="small"
                                type={metricsChecked.find((item: any) => item ? item.name === dataKey : undefined) ? "primary" : "default"}
                                title={getTitle()}
                                disabled={disabled}
                            />
                        </Popover>

                        : null
                }
                {
                    chartType !== "bubble" && (isNumberChosen || isChartChosen) ?
                        <Button
                            onClick={()=>{
                                handleCheckedMetric()
                                setVisibleMorePopovers(false)
                                setVisibleCumulativeChart(false)
                            }}
                            icon={<AlignLeftOutlined rotate={-90}/>}
                            size="small"
                            type={metricsChecked.find((item: any) => item ? item.name === dataKey : undefined) ? "primary" : "default"}
                            title={getTitle()}
                            disabled={disabled}
                        />
                        : null
                }
                {
                    chartType === "line" && !hideCumulativeMetric && isChartChosen ?
                        visibleCumulativeChart ? (
                            <Button
                                onClick={()=>{

                                    setCumulativeChart(false)
                                    setMetricsChecked({ data:[ ...metricsChecked.filter((item) => item.name !== cumulativeDataKey) ] })
                                    setVisibleCumulativeChart((prev)=>!prev)
                                }}
                                icon={<RiseOutlined/>}
                                size="small"
                                type={cumulativeCheckedMetric ? "primary" : "default"}
                                title="Отобразить накопительный график"
                                disabled={disabled}
                            />
                        ): null
                        : null
                }
                {
                    visiblePinFilter ? (
                        <Popover
                            content={<PinPopover dataKey={dataKey} setVisible={setVisiblePin} setFrozens={setFrozens} />}
                            trigger="click"
                            open={visiblePin}
                            onOpenChange={setVisiblePin}
                            placement="bottom"
                        >
                            <Button
                                icon={<PushpinOutlined />}
                                size="small"
                                title="Закрепить колонку"
                                disabled={disabled}
                            />
                        </Popover>
                    ): null
                }


                <Popover
                    content={
                        <div style={{ display:"flex" }}>
                            {
                                column.dataKey.startsWith("_") && <Button
                                    type={"default"}
                                    icon={<EditOutlined />}
                                    size="small"
                                    title="Редактировать"
                                    disabled={disabled}
                                    onClick={()=>{
                                        setVisibleMorePopovers(false)
                                        setEditMetricsState((prev)=>({ ...prev,visible:true }))
                                    }}
                                />
                            }
                            {
                                column.dataKey.startsWith("_") && isNumeric(column?.plan) && isChartChosen &&
                                <Button
                                    type={visibleTargetMetric ? "primary" : "default"}
                                    icon={customIcons.targetSimpleIcon({ style:{ marginTop:"4px" } })}
                                    size="small"
                                    title="Отобразить план"
                                    disabled={disabled}
                                    onClick={()=>{
                                        setVisibleMorePopovers(false)
                                        setVisibleTargetMetric(true)
                                        if(visibleCumulativeChart){
                                            setMetricsChecked({ data:[ ...metricsChecked.filter((item) => item.name !== dataKey), { name: cumulativeDataKey, label: title,plan:column?.plan,base_metric_name:column?.base_metric_name,metric_formula:column?.metric_formula } ] })
                                        }else {
                                            setMetricsChecked({ data:[ ...metricsChecked.filter((item) => item.name !== dataKey), { name: dataKey, label: title,plan:column?.plan,base_metric_name:column?.base_metric_name,metric_formula:column?.metric_formula } ] })
                                        }
                                    }}
                                />
                            }
                            {isCompare ? null : (
                                <Popover
                                    content={<FilterPopover blockId={blockId} filters={filters} dataKey={dataKey} setVisible={setVisibleFilter} setVisibleFilterColumn={setVisibleFilterColumn} />}
                                    trigger="click"
                                    open={visibleFilter}
                                    onOpenChange={setVisibleFilter}
                                    placement="bottom"
                                >
                                    <Button
                                        type={isFiltered ? "primary" : "default"}
                                        icon={<FilterOutlined />}
                                        size="small"
                                        title="Фильтровать по колонке"
                                        disabled={disabled}
                                        onClick={()=>setVisibleMorePopovers(false)}

                                    />
                                </Popover>
                            )}
                            {
                                hideCumulativeMetric ? null :
                                    (
                                        <Button
                                            onClick={()=>{
                                                handlePercentages()
                                                setVisibleMorePopovers(false)
                                                setVisibleAbsoluteValue((prev)=>!prev)
                                            }}
                                            type={isPercentage}
                                            icon={<PercentageOutlined/>}
                                            size="small"
                                            title="Абсолютные значения / Проценты"
                                            disabled={isCompare || disabled}
                                        />
                                    )
                            }
                            {
                                chartType === "bubble" && isChartChosen ?
                                    (
                                        <Popover
                                            content={<MetricsSelectModal dataKey={dataKey} setVisible={setVisibleMetricSelect}/>}
                                            trigger="click"
                                            open={visibleMetricSelect}
                                            onOpenChange={setVisibleMetricSelect}
                                            placement="topLeft"
                                            title={"Выберите ось:"}
                                        >
                                            <Button
                                                icon={<AlignLeftOutlined rotate={-90}/>}
                                                size="small"
                                                type={metricsChecked.find((item: any) => item ? item.name === dataKey : undefined) ? "primary" : "default"}
                                                title={getTitle()}
                                                onClick={()=>{
                                                    setVisibleMorePopovers(false)
                                                    setVisibleShowChart((prev)=>!prev)
                                                }}
                                                disabled={disabled}
                                            />
                                        </Popover>)
                                    : null
                            }
                            {
                                chartType !== "bubble" && (isNumberChosen || isChartChosen) ?
                                    (
                                        <Button
                                            onClick={()=>{
                                                handleCheckedMetric()
                                                setVisibleMorePopovers(false)
                                                setVisibleShowChart((prev)=>!prev)
                                            }}
                                            icon={<AlignLeftOutlined rotate={-90}/>}
                                            size="small"
                                            type={metricsChecked.find((item: any) => item ? item.name === dataKey : undefined) ? "primary" : "default"}
                                            title={getTitle()}
                                            disabled={disabled}
                                        />) : null
                            }
                            {
                                chartType === "line" && !hideCumulativeMetric && isChartChosen ?
                                    (
                                        <Button
                                            onClick={()=>{
                                                if(!isChartVisible){
                                                    localStorage.setItem("isChartVisible",`${!isChartVisible}`)
                                                    setChartVisible()
                                                }
                                                displayCumulativeChart()
                                                setVisibleMorePopovers(false)
                                                setVisibleCumulativeChart((prev)=>!prev)
                                            }}
                                            icon={<RiseOutlined/>}
                                            size="small"
                                            type={cumulativeCheckedMetric ? "primary" : "default"}
                                            title="Отобразить накопительный график"
                                            disabled={disabled}
                                        />) : null
                            }
                            {
                                <Popover
                                    content={<PinPopover dataKey={dataKey} setVisible={setVisiblePin} setFrozens={setFrozens} />}
                                    trigger="click"
                                    open={visiblePin}
                                    onOpenChange={setVisiblePin}
                                    placement="bottom"
                                >
                                    <Button
                                        icon={<PushpinOutlined />}
                                        size="small"
                                        title="Закрепить колонку"
                                        disabled={disabled}
                                        //onClick={()=>setVisiblePinFilter((prev)=>!prev)}
                                    />
                                </Popover>
                            }

                        </div>
                    }
                    trigger="click"
                    open={visibleMorePopovers}
                    onOpenChange={setVisibleMorePopovers}
                    placement="top"
                >
                    <Button
                        icon="..."
                        size="small"
                        disabled={disabled}
                    />
                </Popover>
            </Space>

            <FilterTag filter={filters[dataKey]} onClose={onRemoveFilter} />
            { editMetricsState.visible && <EditMetricsModal confirmCreatedMetricsRemoving={confirmCreatedMetricsRemoving} id={dataKey.replace(/_(\d+?)_/g, "$1")} visible={editMetricsState.visible} setEditState={setEditMetricsState} /> }
        </div>
    )
}


export default HeaderCell
